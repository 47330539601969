//@ts-check
import React from "react";
import { CardContent, Grid } from "@mui/material";

/**
 * @param {import("@mui/material").CardContentProps} props
 */
export default function BaseCardContent({ ...props }) {
  return (
    <CardContent {...props}>
      <Grid container spacing={2}>
        {props.children}
      </Grid>
    </CardContent>
  );
}
