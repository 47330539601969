//@ts-check
import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

/**
 * @typedef {Object} BaseButtonProps
 * @property {string} [label]
 * @property {boolean} [loading]
 * @property {boolean} [hide] *
 * @property {boolean} [close]
 * @property {boolean} [fullWidth]
 */
/**
 * @param {BaseButtonProps & import("@mui/material").ButtonProps} props
 */

export default function BaseButton({
  label,
  loading = false,
  fullWidth = false,
  close = false,
  hide = false,
  ...props
}) {
  return !close ? (
    <Box
      sx={{
        position: "relative",
        width: fullWidth ? "100%" : null,
        display: hide ? "none" : "flex",
        justifyContent: "center",
      }}
    >
      <Button
        {...props}
        id={props.id}
        variant={props.variant ?? "contained"}
        disabled={props.disabled || loading}
        color={props.color ?? "primary"}
        disableElevation={props.disableElevation ?? true}
        sx={{
          textTransform: "none",
          borderRadius: "3px",
          fontSize: "0.8rem",
          ...props?.sx,
        }}
      >
        {label ?? props.children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: "inherit",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  ) : (
    <Button
      id={props.id}
      variant="outlined"
      color="primary"
      disableElevation={props.disableElevation ?? true}
      sx={{
        borderRadius: "3px",
        fontSize: "0.8rem",
        textTransform: "none",
        display: hide ? "none" : "flex",
        ...props?.sx,
      }}
      {...props}
    >
      {label ?? "Close"}
    </Button>
  );
}
