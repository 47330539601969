//@ts-check

import { min } from "moment/moment";
import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";

/**
 * @typedef {Object} BaseNumericInputProps
 * @property {boolean} [allowDecimal]
 * @property {(value: number) => void} [onInputChange] - Callback function that is called when the value changes
 * @property {string} [numericType]
 */
/**
 * @param {BaseNumericInputProps & import("react-number-format").NumericFormatProps} props
 */

export default function BaseNumericInputFormat({
  allowDecimal = false,
  numericType = "",
  onInputChange,
  ...props
}) {
  const [value, setValue] = useState(props.value ?? "0");

  const maxLimit = props.max;
  const minLimit = props.min;

  const handleValueChange = (values) => {
    const { floatValue, value } = values;
    setValue(floatValue !== undefined ? floatValue : value);
    if (onInputChange) {
      onInputChange(floatValue !== undefined ? floatValue : value);
    }
  };

  const handleBlur = () => {
    if (value === "") {
      setValue("0");
    }
  };

  useEffect(() => {
    if (props.readOnly) {
      setValue(props.value ?? "0");
    }
  }, [props.value, props.readOnly]);

  const suffix = props.suffix ?? (numericType === "percent" ? "%" : "");
  const prefix = props.prefix ?? (numericType === "currency" ? "$" : "");

  return (
    <NumericFormat
      {...props}
      id={`numeric-input-${props.name}`}
      suffix={suffix}
      prefix={prefix}
      thousandSeparator={numericType === "currency" ? "," : ""}
      thousandsGroupStyle={numericType === "currency" ? "thousand" : "none"}
      isAllowed={(values) => {
        const { floatValue } = values;
        let successMaxLimit = true;
        let successMinLimit = true;
        if (!floatValue) return true;
        if (!maxLimit && !minLimit) return true;
        if (maxLimit && floatValue > Number(maxLimit)) successMaxLimit = false;
        if (minLimit && floatValue < Number(minLimit)) successMinLimit = false;
        return successMaxLimit && successMinLimit;
      }}
      value={value}
      onValueChange={(values) => handleValueChange(values)}
      onBlur={handleBlur}
      allowNegative={false}
      decimalScale={allowDecimal ? props.decimalScale ?? 2 : 0}
      fixedDecimalScale={props.fixedDecimalScale ?? true}
      style={{
        borderRadius: "0.25rem",
        ...props.style,
      }}
      placeholder={props.placeholder ?? ""}
      name={props.name}
    />
  );
}
