import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingIcon: {
    width: 40,
    height: 40,
    borderRadius: 50,
    border: "3px solid #f3f3f3",
    borderTopColor: "#3498db",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    to: {
      transform: "rotate(360deg)",
    },
  },
  hide: {
    display: "none",
  },
}));

export const Loading = ({ isLoading }) => {
  const classes = useStyles();

  return (
    <div className={isLoading ? classes.loadingOverlay : classes.hide}>
      <div className={classes.loadingIcon}></div>
    </div>
  );
};
