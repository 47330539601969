//@ts-check

import { create } from "zustand";
import { createSelectors } from "../lib/utils";
import { persist } from "zustand/middleware";

const defaultValues = {
  buyerInfo: {
    borrowerFirstName: "",
    borrowerLastName: "",
    borrowerFullName: "",
    borrowerEmail: "",
    borrowerMobilePhone: "",
    borrowerId: "",
    borrowerMthIncome: 0.0,
    coBorrowerFirstName: "",
    coBorrowerLastName: "",
    coBorrowerFullName: "",
    coBorrowerMthIncome: 0.0,
    addtlCoBorrowerIncome: 0.0,
    totalCashInBank: 0.0,
    realtorFirstName: "",
    realtorLastName: "",
    realtorFullName: "",
    realtorEmail: "",
    realtorMobilePhone: "",
    streetAddress: "",
    unitNo: "",
    city: "",
    state: "",
    zipCode: "",
    loanId: "",
  },
  calculationData: {
    noteRate: 0.0,
    loanAmount: 0.0,
    originationFeePerc: 0.0,
    originationFeeAmt: 0.0,
    brokerFeePerc: 0.0,
    brokerFeeAmt: 0.0,
    lenderCreditPerc: 0.0,
    lenderCreditAmt: 0.0,
    discountPointsPerc: 0.0,
    discountPointsAmt: 0.0,
    prepaidInterestDays: 0.0,
    oneTimePMI: 0.0,
    mipOrVAFee: 0.0,
    mtgInsReserveMths: 0.0,
    homeownerInsMo: 0.0,
    hoiReserveMths: 0.0,
    propTaxReserveMths: 0.0,
    floodInsMo: 0.0,
    floodInsReserveMths: 0,
    otherInsMo: 0.0,
    otherInsReserveMths: 0,
    lenderTitleIns: 0.0,
    mtgstamps: 0.0,
    deedstamps: 0.0,
    totalAMTPaidToOthers: 0.0,
    term: 0,
    mgtInsMonthly: 0.0,
    totalHousingExpenses: 0.0,
    purchasePrice: 0.0,
    otherCreditsAmt: 0.0,
    otherCreditsPerc: 0.0,
    loanType: "",
    ltv: 0.0,
    downPaymentPerc: 0.0,
    insMtgInsPeriodFactor1: 0.0,
    allNonVariableFees: 0.0,
    vaFirstUse: true,
    prepaidFinanceCharges: 0.0,
    propTaxMo: 0.0,
  },
};

/**@typedef {Object} BuyerInfo
 * @property {string} borrowerFirstName
 * @property {string} borrowerLastName
 * @property {string} borrowerFullName
 * @property {string} borrowerEmail
 * @property {string} borrowerMobilePhone
 * @property {string} borrowerId
 * @property {number} borrowerMthIncome
 * @property {string} coBorrowerFirstName
 * @property {string} coBorrowerLastName
 * @property {string} coBorrowerFullName
 * @property {number} coBorrowerMthIncome
 * @property {number} addtlCoBorrowerIncome
 * @property {number} totalCashInBank
 * @property {string} realtorFirstName
 * @property {string} realtorLastName
 * @property {string} realtorFullName
 * @property {string} realtorEmail
 * @property {string} realtorMobilePhone
 * @property {string} streetAddress
 * @property {string} unitNo
 * @property {string} city
 * @property {string} state
 * @property {string} zipCode
 * @property {string} loanId
 */

/**@typedef {Object} CalculationData
 * @property {number} noteRate
 * @property {number} loanAmount
 * @property {number} originationFeePerc
 * @property {number} originationFeeAmt
 * @property {number} brokerFeePerc
 * @property {number} brokerFeeAmt
 * @property {number} lenderCreditPerc
 * @property {number} lenderCreditAmt
 * @property {number} discountPointsPerc
 * @property {number} discountPointsAmt
 * @property {number} prepaidInterestDays
 * @property {number} oneTimePMI
 * @property {number} mipOrVAFee
 * @property {number} mtgInsReserveMths
 * @property {number} homeownerInsMo
 * @property {number} hoiReserveMths
 * @property {number} propTaxReserveMths
 * @property {number} floodInsMo
 * @property {number} floodInsReserveMths
 * @property {number} otherInsMo
 * @property {number} otherInsReserveMths
 * @property {number} lenderTitleIns
 * @property {number} mtgstamps
 * @property {number} deedstamps
 * @property {number} totalAMTPaidToOthers
 * @property {number} term
 * @property {number} mgtInsMonthly
 * @property {number} totalHousingExpenses
 * @property {number} purchasePrice
 * @property {number} otherCreditsAmt
 * @property {number} otherCreditsPerc
 * @property {string} loanType
 * @property {number} ltv
 * @property {number} downPaymentPerc
 * @property {number} insMtgInsPeriodFactor1
 * @property {number} allNonVariableFees
 * @property {boolean} vaFirstUse
 * @property {number} prepaidFinanceCharges
 * @property {number} propTaxMo
 */

/**@typedef {Partial<{[K in keyof BuyerInfo]}>} BuyerInfoErrors
 */

/**@typedef {Partial<{[K in keyof CalculationData]}>} CalculationDataErrors
 */

/**@typedef {Object} BuyerLoanData
 * @property {BuyerInfo} buyerInfo
 * @property {CalculationData} calculationData
 */

/**@typedef {Object} LoanStore
 * @property {BuyerInfo} buyerInfo
 * @property {(buyerInfo: BuyerInfo) => void} setBuyerInfo
 * @property {CalculationData} calculationData
 * @property {(calculationData: CalculationData) => void} setCalculationData
 * @property {BuyerInfoErrors} buyerInfoErrors
 * @property {(buyerInfoErrors: BuyerInfoErrors) => void} setBuyerInfoErrors
 * @property {CalculationDataErrors} calculationDataErrors
 * @property {(calculationDataErrors: CalculationDataErrors) => void} setCalculationDataErrors
 * @property {(buyerLoanData: BuyerLoanData) => void} setBuyerLoanData
 */

/**@type {import('zustand').StoreApi<LoanStore>}
 */

const _useLoanData = create(
  persist(
    (set, get) => ({
      buyerInfo: defaultValues.buyerInfo,
      setBuyerInfo: (buyerInfo) => set({ buyerInfo }),
      calculationData: defaultValues.calculationData,
      setCalculationData: (calculationData) => set({ calculationData }),
      buyerInfoErrors: {},
      setBuyerInfoErrors: (buyerInfoErrors) => set({ buyerInfoErrors }),
      calculationDataErrors: {},
      setCalculationDataErrors: (calculationDataErrors) =>
        set({ calculationDataErrors }),
      setBuyerLoanData: (loanData) => {
        get().setBuyerInfo({ ...loanData.buyerInfo });
        get().setCalculationData({ ...loanData.calculationData });
      },
      resetLoanData: () =>
        set({
          buyerInfo: defaultValues.buyerInfo,
          calculationData: defaultValues.calculationData,
          buyerInfoErrors: {},
          calculationDataErrors: {},
        }),
    }),
    {
      name: "loanInfo",
      version: 1,
    }
  )
);

export const useLoanData = createSelectors(_useLoanData);
