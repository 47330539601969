//@ts-check
import React from "react";
import { Box } from "@mui/material";

/**@typedef {import("@mui/material").BoxProps} BaseStickyActionsProps
 * @property {React.ReactNode} children
 */
/**@param {BaseStickyActionsProps & import("@mui/material").BoxProps} props */

export default function BaseStickyActions({ children, ...props }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 2,
        position: "sticky",
        bottom: 0,
        right: 0,
        padding: "16px",
        zIndex: 1000,
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
