import React, { useContext } from "react";
import { Toolbar, Typography, ButtonBase, Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import "../Layout.css";
import LoginMenu from "../auth/LoginMenu";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";
import LogoWhite from "./LogoWhite";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function LayoutToolbar({ handleDrawerToggle }) {

  const { user, onChangeUser } = useContext(CurrentUserContext);

  const theme = useTheme();

  const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Toolbar variant="dense">
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <ButtonBase component={Link} to={"/"}>
          <LogoWhite
            alt="Padzilly"
            height="25"
          />
        </ButtonBase>
      </Typography>
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}
      >
        <ButtonBase component={Link} to={"/"}>
          <LogoWhite
            alt="Padzilly"
            height="25"
          />
        </ButtonBase>
      </Typography>

      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}></Box>

      <Grid className="profile-step">
        {user?.success &&
          !user?.mustChangePassword &&
          user.subscription &&
          new Date(user.subscription?.billingDate) > new Date() ? (
          <LoginMenu user={user} onChangeUser={onChangeUser} />
        ) : null}
      </Grid>
      {
        isMdOrSmaller && user?.id
          ? <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            size="large"
            sx={{ display: { sm: "none", xs: "flex" } }}
          >
            <MenuIcon />
          </IconButton>
          : null
      }
    </Toolbar>
  );
}
