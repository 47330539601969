import React, { useContext } from "react";
import { Drawer } from "@mui/material";
import "../Layout.css";
import MLOMenuLayout from "./MLOMenuLayout";
import { useTheme } from "@mui/material/styles";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";

const drawerWidth = 240;
const miniDrawerWidth = 56;

export default function MobileDrawer({ mobileOpen, handleDrawerToggle }) {
  const { user } = useContext(CurrentUserContext);
  const theme = useTheme();

  return (
    <Drawer
      variant="temporary"
      anchor={theme.direction === "rtl" ? "right" : "left"}
      open={mobileOpen}
      onClose={handleDrawerToggle}
      sx={{
        display: { sm: "none", xs: "flex" },
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          marginTop: "48px",
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {user?.role && user?.role === "MLO-Lender" ? <MLOMenuLayout handleClose={() => handleDrawerToggle()} /> : ""}
    </Drawer>
  );
}
