import axios from "axios";

/**
 * The function `getApiRootPath` returns the appropriate API root path based on the current window
 * location.
 * @returns {string} The API root path.
 */
export function getApiRootPath() {
  if (window.location.origin.startsWith("https://qa"))
    return "https://qa-api.padzilly.app/";
  if (
    window.location.origin.startsWith("https://www.") ||
    window.location.origin.startsWith("https://padzilly.com") ||
    window.location.origin.startsWith("https://padzilly.app")
  )
    return "https://api.padzilly.app/";

  //return "https://qa-api.padzilly.app/";
  return "https://localhost:7045/";
}

export const BASE_URL = getApiRootPath();
const TIMEOUT = 30000;

export const client = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
});
