import { useEffect, useState } from "react";
import { formValidation } from "../../src/components/core/validationFunctions";
import { LOAN_VALIDATIONS } from "../../src/components/core/constants/constants";

export const Patterns = {
  YEAR: "{{YEAR}}",
  DATE_YEAR: "{{DATE_YEAR}",
  DATE_TIME: "{{DATE-TIME}}",
  DATE: "{{Date}}",
  BROKERAGE: "{{BROKERAGE}}",
};

export const replacePatterns = (text) => {
  const currentYear = String(new Date().getFullYear());
  const currentDateTime = new Date().toLocaleString();
  const currentDate = new Date().toLocaleDateString();

  var result = text
    .replaceAll(Patterns.YEAR, currentYear)
    .replaceAll(Patterns.DATE_YEAR, currentYear)
    .replaceAll(Patterns.DATE, currentDate)
    .replaceAll(Patterns.DATE_TIME, currentDateTime);

  return result;
};

export const formatCurrency = (amount = 0, precision = 0) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(isNaN(amount) ? 0 : amount);
};
/**
 *
 * @param {import("../types/Property").Property} property
 * @returns {string} The `getAddress` function takes a `property` object as an argument and returns a string that represents the address of the property. If the `fullAddress` property is present, it returns that value. Otherwise, it returns the `streetAddress`, `city`, `state`, and `zipCode` properties concatenated with commas and spaces. If any of these properties are missing, it returns 'Unknown Address'.
 */
export const getAddress = (property) => {
  const address = `${
    property.fullAddress ?? property.streetAddress ?? "Unknown Address"
  }, ${property.city ?? "Unknown City"}, ${property.state ?? "Unknown State"} ${
    property.zipCode ?? "Unknown Zip Code"
  }`;
  return address === "" ? "Unknown Address" : address;
};

/**
 * Adds selector functions to a store object.
 * @template S - The store object type.
 * @param {S} _store - The store object.
 * @returns {WithSelectors<S>} - The store object with added selector functions.
 */
export function createSelectors(_store) {
  /** @type {WithSelectors<typeof _store>} */
  const store = _store;
  store.use = {};

  for (const k of Object.keys(store.getState())) {
    store.use[k] = () => store((s) => s[k]);
  }

  return store;
}

/**
 * Describes a store object with added selector functions.
 * @template S - The store object type.
 * @typedef {S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never} WithSelectors
 */

/**
 *
 * @param {number | string| undefined} value
 * @param {boolean} nullable
 *
 */
export function setCurrencyValue(value, nullable = true) {
  if (value) {
    value = value.toString();
    value = value.replace(/[^\d]/g, "");
    if (value === "") {
      return nullable ? null : "0";
    }
    value = value.replace(/^0+/, "");
    if (value === "") {
      return nullable ? null : "0";
    }
    let formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  } else {
    if (nullable) {
      return null;
    } else {
      return "0";
    }
  }
}
/**
 *
 * @param {any} loan
 * @param {number} downPayment
 * @returns
 */
export const getSellerRebateMax = (loan, downPayment) => {
  const { loanType, occupancyType } = loan || {};

  if (!loanType) {
    return 0;
  }

  // const isPrimaryOrSecond = ['PrimaryResidence', 'SecondHome'].includes(occupancyType)

  switch (loanType) {
    case "Conventional":
      if (downPayment < 10) return 3;
      if (downPayment < 25) return 6;
      return 9;
    case "FHA":
      return 6;
    case "VA":
      if (occupancyType === "PrimaryResidence") return 4;
      break;
    case "FarmersHomeAdministration":
      if (occupancyType === "PrimaryResidence") return 6;
      break;
    default:
      break;
  }

  return 0;
};

/**
 *
 * @param {number} basePrice
 * @param {number} currentPrice
 * @returns {string} The `calculatePriceChangePercentage` function takes two numbers as arguments and returns a string representing the percentage change between the two values. If the base price is less than or equal to zero, it throws an error. The function first calculates the percentage change by subtracting the base price from the current price and dividing it by the base price. It then rounds the result to two decimal places and adds a plus sign if the change is positive. Finally, it returns the formatted percentage change as a string.
 */
export function calculatePriceChangePercentage(basePrice, currentPrice) {
  if (basePrice <= 0) {
    throw new Error("El precio base debe ser mayor que cero");
  }

  const changePercentage = ((currentPrice - basePrice) / basePrice) * 100;

  // Redondear a dos decimales y añadir el signo
  return changePercentage > 0
    ? `+${changePercentage.toFixed(1)}`
    : changePercentage.toFixed(1);
}

/**
 *
 * @param {number} associationFee
 * @param {string} associationFeeFrequency
 * @returns {number}
 */
export const calculateMonthlyAssociationFee = (
  associationFee,
  associationFeeFrequency = "Monthly"
) => {
  let monthlyFee = 0;

  if (associationFeeFrequency === "Monthly") {
    monthlyFee = associationFee;
  } else if (associationFeeFrequency === "Quarterly") {
    monthlyFee = associationFee / 3;
  } else if (associationFeeFrequency === "Annually") {
    monthlyFee = associationFee / 12;
  } else {
    monthlyFee = associationFee;
  }

  return monthlyFee;
};

export const validateInitialLoanValues = (loanData) => {
  const errorsList = {};
  Object.keys(LOAN_VALIDATIONS).forEach((key) => {
    const value = loanData[key];
    if (value != undefined) {
      const error = formValidation(key, value, LOAN_VALIDATIONS) || "";
      if (error !== "") {
        errorsList[key] = error;
      }
    }
  });
  return errorsList;
};
