//@ts-check
import React from "react";
import { CardHeader } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    letterSpacing: "-0.025rem",
    lineHeight: 1,
    "& .MuiTypography-root": {
      fontWeight: 400,
      fontSize: "1.2rem",
    },
    whiteSpace: "nowrap",
  },
}));

/**
 * @param {import("@mui/material").CardHeaderProps} props
 */
export default function BaseCardHeader({ ...props }) {
  const classes = useStyles();

  return (
    <CardHeader
      className={classes.cardHeader}
      title={props.title}
      subheader={props.children}
      sx={{ paddingBottom: 0, ...props.sx }}
      {...props}
    />
  );
}
