import React, { useContext } from "react";
import { Drawer, Box, Toolbar } from "@mui/material";
import "../Layout.css";
import MLOMenuLayout from "./MLOMenuLayout";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";

const drawerWidth = 240;

export default function DesktopDrawer() {
  const { user } = useContext(CurrentUserContext);

  return (
    <Drawer
      sx={{
        display: { sm: "flex", xs: "none" },
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          marginTop: "2.5rem",
        },
      }}
      variant="permanent"
      open
    >
      <Box sx={{ overflow: "auto" }}>
        {user?.role && user?.role === "MLO-Lender" ? <MLOMenuLayout /> : ""}
      </Box>
    </Drawer>
  );
}
