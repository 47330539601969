import * as React from "react"
const LogoWhite = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 547.26 141.16"
        {...props}
    >
        <defs>
            <style>{".cls-2{fill:#fff}"}</style>
        </defs>
        <path
            d="m475.75 468.31-54.38 54.38v49.89h28v-30.09h36.28a7.91 7.91 0 0 0 7.91-7.91v-48.43Zm-6.86 62.76h-12.42v-12.43h12.42Zm0-15.92h-12.42v-12.42h12.42Zm15.88 15.92h-12.44v-12.43h12.44Zm0-15.92h-12.44v-12.42h12.44Z"
            style={{
                fill: "#eb5a2d",
            }}
            transform="translate(-421.37 -431.42)"
        />
        <path
            d="M55.48 0H0v71.3l7.08-7.08V36.89h13.89v13.44l33.42-33.41 17.84 17.85V17.14C72.23 6.83 66.63 0 55.48 0ZM310.14 0h-66v26.29h29.39L241.8 141.16h64.45v-26.28h-26.48ZM317.94 0v141.16h28.22V0ZM386.85 114.88V0h-29v141.16h57.43v-26.28ZM453.06 114.88V0h-29v141.16h57.44v-26.28ZM518.06 0l-12.85 66.58-12.65-66.6h-29.2l27 105.33v35.83h29.79v-35.81L547.21 0ZM129.63.01H99.48L73.97 141.16h28.43l2.08-15.23h20.21l2 15.23h28.43Zm-21.59 99.64 6.6-48.44 6.5 48.44ZM204.06.01h-41.11v141.15h41.11a30.93 30.93 0 0 0 30.93-30.93v-79.3A30.92 30.92 0 0 0 204.06.01Zm2.93 101.49a11.66 11.66 0 0 1-11.66 11.66h-4.38V28.01h4.38a11.66 11.66 0 0 1 11.66 11.66Z"
            className="cls-2"
        />
    </svg>
)
export default LogoWhite;
