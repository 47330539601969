import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import SettingsIcon from "@mui/icons-material/Settings";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "../Layout.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Fragment } from "react";
import { AppRoutes } from "../core/PathConstants";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupportIcon from '@mui/icons-material/Support';
import { CurrentUserContext } from "../../hooks/CurrentUserContext";

export default function MLOMenuLayout({ handleClose }) {
  const [path, setPath] = useState(window.location.pathname.replace("/lenders", ""));
  const handleListItemClick = (event, newPath) => setPath(newPath);
  const { user } = useContext(CurrentUserContext);

  return (
    <Fragment>
      <List>
        <ListItemButton
          className="mlo-second-step"
          component={Link}
          to={AppRoutes.BuyersAndLoans}
          onClick={(event) => {
            handleListItemClick(event, AppRoutes.BuyersAndLoans)
            if (handleClose) {
              handleClose();
            }
          }}
          selected={path === AppRoutes.BuyersAndLoans || path === "/" || path == ""}
        >
          <ListItemIcon>
            <PeopleAltIcon
              style={{
                color:
                  path === AppRoutes.BuyersAndLoans
                    || path === "/"
                    || path === ""
                    ? "#033a7c"
                    : "inherit"
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Buyers" />
        </ListItemButton>
        <ListItemButton
          className="lender-second-step"
          component={Link}
          to={AppRoutes.CompanyConfig}
          onClick={(event) => {
            handleListItemClick(event, AppRoutes.CompanyConfig);
            if (handleClose) {
              handleClose();
            }
          }}
          selected={path === AppRoutes.CompanyConfig}
        >
          <ListItemIcon>
            <SettingsIcon style={{ color: path === AppRoutes.CompanyConfig ? "#033a7c" : "inherit" }} />
          </ListItemIcon>
          <ListItemText primary="Company Settings" />
        </ListItemButton>
        <Divider />
        <ListItemButton
          className="mlo-third-step"
          component={Link}
          to={AppRoutes.Profile}
          onClick={(event) => {
            handleListItemClick(event, AppRoutes.Profile);
            if (handleClose) {
              handleClose();
            }
          }}
          selected={path === AppRoutes.Profile}
        >
          <ListItemIcon>
            <AccountCircleIcon style={{ color: path === AppRoutes.Profile ? "#033a7c" : "inherit" }} />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </ListItemButton>
        <Divider />
        <ListItemButton
          className="mlo-fourth-step"
          component={Link}
          to={path}
          onClick={() => { window.open("https://lender-support.padzilly.net/?jwt=" + user.supportToken, "_blank") }}
          selected={false}
        >
          <ListItemIcon>
            <SupportIcon />
          </ListItemIcon>
          <ListItemText primary="Support" />
        </ListItemButton>
      </List>
    </Fragment>
  );
}
