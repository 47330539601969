import React, { useEffect, useState, useContext } from "react";
import BaseContainer from "../core/base-components/BaseContent/BaseContainer";
import BaseTitle from "../core/base-components/BaseContent/BaseTitle";
import BaseTitleActions from "../core/base-components/BaseContent/BaseTitleActions";
import BaseButton from "../core/base-components/BaseButton";
import { AppRoutes } from "../core/PathConstants";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { useLoanById } from "../../api/loans/use-loans";
import LoanEditorForm from "./loan-editor/LoanEditorForm";
import { useLoanData } from "../../store/use-loan-data";
import { LOAN_EDITOR_MODES } from "../core/constants/constants";
import { Loading } from "../core/Loading";
import AlertMessage from "../core/AlertMessage";
import { useQueryClient } from "@tanstack/react-query";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";

export default function LoanEditor() {
  const { user } = useContext(CurrentUserContext);
  const loanId = new URLSearchParams(location.search).get("loanId") ?? null;
  const buyerId = new URLSearchParams(location.search).get("buyerId") ?? null;
  const mode = loanId
    ? LOAN_EDITOR_MODES.EDIT_LOAN
    : buyerId
    ? LOAN_EDITOR_MODES.ADD_LOAN
    : LOAN_EDITOR_MODES.CREATE_LOAN;

  const getTitle = () => {
    switch (mode) {
      case LOAN_EDITOR_MODES.EDIT_LOAN:
        return "Edit Loan";
      case LOAN_EDITOR_MODES.ADD_LOAN:
        return "Add Loan";
      case LOAN_EDITOR_MODES.CREATE_LOAN:
        return "Create New Loan";
    }
  };

  const history = useHistory();
  const queryClient = useQueryClient();

  const { data: loanData, isLoading, isError } = useLoanById(loanId);
  const [dataLoaded, setDataLoaded] = useState(false);

  const setBuyerLoanData = useLoanData.use.setBuyerLoanData();
  const resetLoanData = useLoanData.use.resetLoanData();

  const [alertState, setAlertState] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    if (loanData) {
      setBuyerLoanData({
        buyerInfo: {
          borrowerFirstName: loanData.borrowerFirstName || "",
          borrowerLastName: loanData.borrowerLastName || "",
          borrowerFullName: loanData.borrowerFullName || "",
          borrowerEmail: loanData.borrowerEmail || "",
          borrowerMobilePhone: loanData.borrowerMobilePhone || "",
          borrowerId: loanData.borrowerId,
          borrowerMthIncome: loanData.borrowerMthIncome || 0.0,
          coBorrowerFirstName: loanData.coBorrowerFirstName || "",
          coBorrowerLastName: loanData.coBorrowerLastName || "",
          coBorrowerFullName: loanData.coBorrowerFullName || "",
          coBorrowerMthIncome: loanData.coBorrowerMthIncome || 0.0,
          addtlCoBorrowerIncome: loanData.addtlCoBorrowerIncome || 0.0,
          totalCashInBank: loanData.totalCashInBank || 0.0,
          realtorFirstName: loanData.realtorFirstName || "",
          realtorLastName: loanData.realtorLastName || "",
          realtorFullName: loanData.realtorFullName || "",
          realtorEmail: loanData.realtorEmail || "",
          realtorMobilePhone: loanData.realtorMobilePhone || "",
          streetAddress: loanData.streetAddress || "",
          unitNo: loanData.unitNo || "",
          city: loanData.city || "",
          state: loanData.state || "",
          zipCode: loanData.zipCode || "",
          loanId: loanData.loanId,
        },
        calculationData: {
          noteRate: loanData.noteRate,
          loanAmount: loanData.loanAmount,
          originationFeePerc: loanData.originationFeePerc,
          originationFeeAmt: loanData.originationFeeAmt,
          brokerFeePerc: loanData.brokerFeePerc,
          brokerFeeAmt: loanData.brokerFeeAmt,
          lenderCreditPerc: loanData.lenderCreditPerc,
          lenderCreditAmt: loanData.lenderCreditAmt,
          discountPointsPerc: loanData.discountPointsPerc,
          discountPointsAmt: loanData.discountPointsAmt,
          prepaidInterestDays: loanData.prepaidInterestDays,
          oneTimePMI: loanData.oneTimePMI,
          mipOrVAFee: loanData.mipOrVAFee,
          mtgInsReserveMths: loanData.mtgInsReserveMths,
          homeownerInsMo: loanData.homeownerInsMo,
          hoiReserveMths: loanData.hoiReserveMths,
          propTaxReserveMths: loanData.propTaxReserveMths,
          floodInsMo: loanData.floodInsMo,
          floodInsReserveMths: loanData.floodInsReserveMths,
          otherInsMo: loanData.otherInsMo,
          otherInsReserveMths: loanData.otherInsReserveMths,
          lenderTitleIns: loanData.lenderTitleIns,
          mtgstamps: loanData.mtgstamps,
          deedstamps: loanData.deedstamps,
          totalAMTPaidToOthers: loanData.totalAMTPaidToOthers,
          term: loanData.term,
          mgtInsMonthly: loanData.mgtInsMonthly,
          totalHousingExpenses: loanData.totalHousingExpenses,
          purchasePrice: loanData.purchasePrice,
          otherCreditsAmt: loanData.otherCreditsAmt,
          otherCreditsPerc: loanData.otherCreditsPerc,
          loanType: loanData.loanType,
          ltv: loanData.ltv,
          downPaymentPerc: loanData.downPaymentPerc,
          insMtgInsPeriodFactor1: loanData.insMtgInsPeriodFactor1,
          allNonVariableFees: loanData.allNonVariableFees,
          vaFirstUse: loanData.vaFirstUse,
          prepaidFinanceCharges: loanData.prepaidFinanceCharges,
          propTaxMo: loanData.propTaxMo,
        },
      });
      setDataLoaded(true);
    }
  }, [loanData]);

  const onSubmitSuccess = () => {
    setTimeout(() => {
      history.push(AppRoutes.BuyersAndLoans);
    }, 1000);
    queryClient.invalidateQueries(["buyersLoans", user.id]);
    queryClient.invalidateQueries(["invitationsLoans", user.id]);
    resetLoanData();
    setAlertState({
      show: true,
      message:
        mode !== LOAN_EDITOR_MODES.EDIT_LOAN
          ? "Loan created successfully"
          : "Loan data updated successfully",
      type: "success",
    });
  };

  if (mode === LOAN_EDITOR_MODES.EDIT_LOAN && (isLoading || !dataLoaded))
    return <Loading isLoading={isLoading || !dataLoaded} />;

  return (
    <BaseContainer>
      <AlertMessage alert={alertState} />
      <BaseTitle title={getTitle()}>
        <BaseTitleActions>
          <BaseButton
            variant="outlined"
            label={"Back to List"}
            onClick={() => {
              resetLoanData();
              history.push(AppRoutes.BuyersAndLoans);
            }}
          />
        </BaseTitleActions>
      </BaseTitle>
      <Grid item xs={12}>
        <LoanEditorForm mode={mode} onSubmitSuccess={onSubmitSuccess} />
      </Grid>
    </BaseContainer>
  );
}
