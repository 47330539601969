import { Upload } from "@mui/icons-material";
import LoanEditor from "../loans/LoanEditor";

const apiProjectPrefix = "api";

export const ApiPaths = {
  Login: `${apiProjectPrefix}/Auth/login`,
  LogOut: `${apiProjectPrefix}/Auth/logout`,
  Refresh: `${apiProjectPrefix}/Auth/refresh`,
  ForgotPwd: `${apiProjectPrefix}/Auth/forgotpwd`,
  ChangePwd: `${apiProjectPrefix}/Auth/changepwd`,
  ChangeResetPwd: `${apiProjectPrefix}/Auth/change-reset-pwd`,
  VerifyOTP: `${apiProjectPrefix}/Auth/verify-user`,
  ResendOTP: `${apiProjectPrefix}/Auth/resend-otp`,
  SignUp: `${apiProjectPrefix}/Auth/sign-up`,
  SendOTPForPassword: `${apiProjectPrefix}/Auth/sendpwdotp`,
  CreateSubscription: `${apiProjectPrefix}/Auth/create-subscription`,

  States: `${apiProjectPrefix}/states`,
  Company: `${apiProjectPrefix}/company`,
  CompanyUICustomization: `${apiProjectPrefix}/company/customization/ui`,
  UserProfile: `${apiProjectPrefix}/user`,

  MLOriginatorDetails: `${apiProjectPrefix}/mlo/details`,

  CheckBuyerByEmail: `${apiProjectPrefix}/buyer/check-by-email`,
  MLOPendingBuyers: `${apiProjectPrefix}/buyer/mlo-pending-buyers`,
  OfferLetter: `${apiProjectPrefix}/buyer/offer-letter-for-buyer`,

  PDFParser: `${apiProjectPrefix}/loans/pdf-parser`,
  Loans: `${apiProjectPrefix}/loans`,
  BuyerSavedProperties: `${apiProjectPrefix}/listedProperties/get-buyer-favorite-properties`,
  SaveFavoriteProperty: `${apiProjectPrefix}/listedProperties/save-buyer-fav-property`,

  ToggleDisclosureRate: `${apiProjectPrefix}/investmentProfile/update-disclosure-rate-option`,
  InvestmentProfileById: `${apiProjectPrefix}/investmentProfile/get-by-id`,

  MLOBuyers: `${apiProjectPrefix}/buyer/mlo-buyers`,
};

export const AppRoutes = {
  Home: "/",
  Login: "/auth/login",
  ForgotPasswprd: "/auth/forgotPassword",
  SignUp: "/auth/sign-up",
  VerifyOTPToResetPwd: "/auth/verify-code-to-change-password",
  VerifyOTP: "/auth/verify-code",
  ChangePassword: "/auth/change-password",

  SubscriptionPlans: "/auth/subscription-plans",
  BasicPlan: "/auth/subscription-plans/basic-plan",
  EnterprisePlan: "/auth/subscription-plans/enterprise-plan",
  PremiumPlan: "/auth/subscription-plans/premium-plan",

  ChangeResetPasswordFromProfile: "/profile/change-password",
  Profile: "/profile",

  CompanyConfig: "/company/config",
  BuyersAndLoans: "/buyers",
  BuyeSavedProperties: "/buyers/:buyerId",
  CreateLoan: "/buyers/loans/create",
  EditLoan: "/buyers/loans/edit",
  UploadLoan: "/buyers/loans/upload",
  EditUploadedLoan: "/buyers/loans/upload/edit",
};

export const ExternalUrls = {
  LearnMoreLoadingPage:
    "https://app.groove.cm/groovevideo/videopage/183332/mskha2624e56ef67d4cbbdb1cd7ed9f2c74",
  DealMakerTool:
    "https://app.groove.cm/groovevideo/videopage/183107/mskha2624e56ef67d4cbbdb1cd7ed9f2c747c",
  LearnMoreCalcList:
    "https://app.groove.cm/groovevideo/videopage/183107/mskha2624e56ef67d4cbbdb1cd7ed9f2c747c",
};
