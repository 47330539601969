export const COLORS = {
  primary: "#033a7c",
  secondary: "#F1592A",
  success: "#5da160",
  warning: "#FFB547",
  error: "#fd3524",
  info: "#006064",
  backgroundInfo: "#033a7c20",
};

export const RADIUS = {
  sm: "0.25rem",
  md: "0.5rem",
  lg: "1rem",
  xl: "1.5rem",
  "2xl": "2rem",
  "3xl": "3rem",
  full: "9999px",
};
