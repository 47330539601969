//@ts-check
import { client } from "../common";
import * as React from "react";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";
import { ApiPaths } from "../../components/core/PathConstants";
import { useQuery } from "@tanstack/react-query";

export const useMLOBuyersLoans = () => {
  const { user } = React.useContext(CurrentUserContext);
  return useQuery(["buyersLoans", user?.id], () => fetchMLOBuyersLoans(user), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};

const fetchMLOBuyersLoans = async (user) => {
  return client
    .get(ApiPaths.MLOBuyers, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => response.data);
};

export const useMLOInvitationsLoans = () => {
  const { user } = React.useContext(CurrentUserContext);
  return useQuery(
    ["invitationsLoans", user?.id],
    () => fetchMLOInvitationsLoans(user),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

const fetchMLOInvitationsLoans = async (user) => {
  return client
    .get(ApiPaths.MLOPendingBuyers, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => response.data);
};

export const useLoanById = (loanId) => {
  const { user } = React.useContext(CurrentUserContext);
  return useQuery(["loan", loanId], () => fetchLoanById(user, loanId), {
    refetchOnWindowFocus: false,
    enabled: !!loanId,
  });
};

const fetchLoanById = async (user, loanId) => {
  return client
    .get(`${ApiPaths.Loans}?id=${loanId}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => response.data);
};
