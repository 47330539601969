//@ts-check
import { useContext } from "react";
import { client } from "../common";
import { ApiPaths, AppRoutes } from "../../components/core/PathConstants";
import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";

export const useLogin = () => {
  const { onChangeUser } = useContext(CurrentUserContext);
  const history = useHistory();

  const login = async ({ email, password }) => {
    return client
      .post(ApiPaths.Login, { email, password, role: "Partner" })
      .then((response) => response.data);
  };
  return useMutation(login, {
    onSuccess: (user) => {
      if (user?.hasConfirmedOTP) {
        onChangeUser(user);
        if (!user?.subscription || !user?.subscription?.active) {
          history.push(AppRoutes.SubscriptionPlans);
          return;
        }
        history.push(AppRoutes.Home);
      } else {
        history.push(
          `${AppRoutes.VerifyOTP}?id=${user?.userId}&date=${user?.otpExpirationDate}`
        );
      }
    },
  });
};

export const useRefresh = () => {
  const history = useHistory();

  const refresh = async ({ email, refreshToken }) => {
    return client
      .put(ApiPaths.Refresh, { email: email, refreshToken: refreshToken })
      .then((response) => response.data);
  };
  return useMutation(refresh);
};

export const useChangePassword = () => {
  const changePassword = async ({ password, email }) => {
    return client
      .post(ApiPaths.ChangeResetPwd, { password, email })
      .then((response) => response.data);
  };
  return useMutation(changePassword);
};

export const useSignUp = () => {
  const history = useHistory();

  const signUp = async (userData) => {
    return client
      .post(ApiPaths.SignUp, userData)
      .then((response) => response.data);
  };
  return useMutation(signUp, {
    onSuccess: (data) => {
      let currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() + 15);
      const currentDateTimestamp = Math.floor(currentDate.getTime() / 1000);
      history.push(
        `${AppRoutes.VerifyOTP}?id=${data.userId}&date=${currentDateTimestamp}`
      );
    },
  });
};
