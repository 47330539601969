//@ts-check
import { client } from "../common";
import { ApiPaths } from "../../components/core/PathConstants";

export const fetchBuyerByEmail = async (user, email) => {
  return client
    .get(`${ApiPaths.CheckBuyerByEmail}?email=${email}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      response.data;
    });
};
