import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, Fade, IconButton, Paper, Popper } from "@mui/material";
import { useHistory } from "react-router-dom";
import { AppRoutes, ApiPaths } from "../core/PathConstants";
import { postApiData } from "../core/FetchApiData";
import Logout from '@mui/icons-material/Logout';
import { deepOrange } from "@mui/material/colors";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function LoginMenu({ user, onChangeUser }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleLogout = () => {
    postApiData(ApiPaths.LogOut, "put", null, onChangeUser, null, user.token);
    onChangeUser({});
    setAnchorEl(null);
    history.push(AppRoutes.Home);
  };

  const popperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popperRef.current && !popperRef.current.contains(event.target) && !anchorEl.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const authenticatedView = () => {
    return (
      <>
        <Popper
          sx={{ zIndex: 99999 }}
          open={open}
          anchorEl={anchorEl}
          placement={"bottom"}
          transition
          ref={popperRef}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{
                display: "flex",
                flexDirection: "column",
                padding: "8px"
              }}>
                <Button
                  onClick={handleLogout}
                  style={{
                    fontSize: ".7rem",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Logout fontSize="small" />&nbsp;<span>Logout</span>
                </Button>
                <Button
                  onClick={() => {
                    history.push(AppRoutes.Profile);
                    setOpen(false);
                  }}
                  style={{
                    fontSize: ".7rem",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <AccountCircleIcon fontSize="small" />&nbsp;<span>Profile</span>
                </Button>
              </Paper>
            </Fade>
          )}
        </Popper>
        <IconButton
          aria-controls="account-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Avatar
            src={user.picture}
            sx={{
              bgcolor: "secondary.main",
              width: 30,
              height: 30,
              fontSize: "0.9rem",
              textTransform: "uppercase",
              bgcolor: deepOrange[400],
            }}
          >
            {(
              (user?.firstName || "").substring(0, 1) +
              (user?.lastName || "").substring(0, 1)
            )}
          </Avatar>
        </IconButton>
      </>
    );
  };

  if (user?.success) {
    return authenticatedView();
  } else {
    return <></>;
  }
}
