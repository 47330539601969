//@ts-check
import { Box } from "@mui/material";
import React from "react";

/**
 * @typedef {Object} BaseTitleActionsProps
 * @property {React.ReactNode} children
 */
/**@param {BaseTitleActionsProps & import("@mui/material").BoxProps} props */

export default function BaseTitleActions({ children, ...props }) {
  return (
    <Box sx={{ display: "flex", gap: "10px", ...props.sx }} {...props}>
      {children}
    </Box>
  );
}
