//@ts-check
import React, { useEffect } from "react";
import { useLoanData } from "../../../store/use-loan-data";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import _ from "lodash";
import { LOAN_VALIDATIONS } from "../../core/constants/constants";
import { formValidation } from "../../core/validationFunctions";
import BaseCard from "../../core/base-components/BaseCard/BaseCard";
import BaseCardHeader from "../../core/base-components/BaseCard/BaseCardHeader";
import BaseCardContent from "../../core/base-components/BaseCard/BaseCardContent";
import { BaseCheckBox } from "../../core/base-components/BaseCheckBox";
import BaseNumericInputFormat from "../../core/base-components/BaseNumericInputFormat";

const useStyles = makeStyles((theme) => ({
  checkBoxLabel: {
    "& .MuiTypography-root": {
      fontSize: "0.9rem",
    },
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#282887",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8rem",
  },
  loanPercentTextfields: {
    width: 70,
    marginRight: "0.2rem",
    height: "25px",
    border: "1px solid rgb(0 0 0 / 23%)",
  },
  loanPercentTextfieldsError: {
    width: 70,
    marginRight: "0.2rem",
    height: "25px",
    border: "2px solid rgb(205 18 18 / 57%)",
  },
  loanBigTextfields: {
    width: 130,
    height: "25px",
    border: "1px solid rgb(0 0 0 / 23%)",
  },
  loanBigTextfieldsError: {
    width: 130,
    height: "25px",
    border: "2px solid rgb(205 18 18 / 57%)",
  },
  loanLabels: {
    fontSize: "0.9rem",
  },
  loanGrid: {
    margin: "0.5rem 0 0.5rem 0",
    justifyContent: "space-between",
  },
  loanGridItem: {
    justifyContent: "flex-start",
  },
}));

export default function LoanCalculationForm({
  isValidLoanType,
  setIsValidLoanType,
}) {
  const calculationData = useLoanData.use.calculationData();
  const setCalculationData = useLoanData.use.setCalculationData();
  const formErrors = useLoanData.use.calculationDataErrors();
  const setFormErrors = useLoanData.use.setCalculationDataErrors();

  const ltvValue =
    calculationData.loanType == "FHA" || calculationData.loanType == "VA"
      ? ((calculationData.loanAmount - calculationData.oneTimePMI) /
        calculationData.purchasePrice) *
      100
      : (calculationData.loanAmount / calculationData.purchasePrice) * 100;

  const classes = useStyles();

  const handleCheckLoanType = (e) => {
    let { value, checked } = e.target;
    const loanType = checked ? value : "";

    if (loanType != "") {
      setIsValidLoanType(true);
    } else {
      setIsValidLoanType(false);
    }
    setCalculationData({
      ...calculationData,
      loanType: loanType,
    });
    if (loanType === "Conventional") {
      setFormErrors({
        ...formErrors,
        oneTimePMI: "",
      });
    } else {
      setFormErrors({
        ...formErrors,
        oneTimePMI: formValidation(
          "oneTimePMI",
          calculationData.oneTimePMI,
          LOAN_VALIDATIONS
        ),
      });
    }
  };

  const handleCheckHasSinglePMI = (e) => {
    let { value, checked } = e.target;
    setCalculationData({
      ...calculationData,
      oneTimePMI: checked ? 1.0 : 0.0,
    });
  };

  const handleCheckHasUsedVABefore = (e) => {
    let { value, checked } = e.target;

    setCalculationData({
      ...calculationData,
      vaFirstUse: !checked,
    });
  };

  const handleNumericValues = (value, name) => {
    setCalculationData({
      ...calculationData,
      [name]: value,
    });

    if (name in LOAN_VALIDATIONS) {
      const error = formValidation(name, value, LOAN_VALIDATIONS) || "";

      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    }
  };

  const loanType = () => {
    return (
      <Grid
        container
        direction={"row"}
        sx={{
          margin: "0 0 0.5rem 0",
        }}
        alignItems={"center"}
      >
        <Grid container item xs={12}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            <span className={classes.circle}>1</span>
          </div>
          <span
            style={{
              fontSize: "0.9rem",
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
              alignContent: "center",
              color: isValidLoanType ? "#000000" : "#ff0c0c",
            }}
          >
            Please select loan type
          </span>
          <BaseCheckBox
            formControlLabelProps={{
              label: "Conventional",
              className: classes.checkBoxLabel,
              sx: {
                color: isValidLoanType ? "#000000" : "#ff0c0c",
              },
            }}
            sx={{
              color: isValidLoanType ? "#000000" : "#ff0c0c",
            }}
            value={"Conventional"}
            onClick={handleCheckLoanType}
            checked={calculationData.loanType === "Conventional"}
          />
          <BaseCheckBox
            formControlLabelProps={{
              label: "FHA",
              className: classes.checkBoxLabel,
              sx: {
                color: isValidLoanType ? "#000000" : "#ff0c0c",
              },
            }}
            sx={{
              color: isValidLoanType ? "#000000" : "#ff0c0c",
            }}
            value={"FHA"}
            onClick={handleCheckLoanType}
            checked={calculationData.loanType === "FHA"}
          />
          <BaseCheckBox
            formControlLabelProps={{
              label: "VA",
              sx: {
                display: "none",
                color: isValidLoanType ? "#000000" : "#ff0c0c",
              },
            }}
            sx={{ display: "none" }}
            value={"VA"}
            onClick={handleCheckLoanType}
            checked={calculationData.loanType === "VA"}
          />
          <BaseCheckBox
            formControlLabelProps={{
              label: "Has single premium PMI",
              className: classes.checkBoxLabel,
              sx: {
                display:
                  calculationData.loanType === "Conventional" &&
                    calculationData.ltv > 80
                    ? "inline-flex"
                    : "none",
                color: isValidLoanType ? "#000000" : "#ff0c0c",
              },
            }}
            sx={{
              display:
                calculationData.loanType === "Conventional" &&
                  calculationData.ltv > 80
                  ? "inline-flex"
                  : "none",
            }}
            onClick={handleCheckHasSinglePMI}
            name="hasOneTimePMI"
            checked={calculationData.oneTimePMI === 1}
          />
          <BaseCheckBox
            formControlLabelProps={{
              label: "Borrower HAS USED VA BEFORE",
              sx: {
                fontSize: "0.9rem",
                display: "none",
              },
            }}
            value={-1}
            onClick={handleCheckHasUsedVABefore}
            name="vaFirstUse"
            checked={!calculationData.vaFirstUse}
            style={{
              //display: calculationData.loanType === "VA" ? "inline-flex" : "none",
              display: "none",
            }}
          />
        </Grid>

        <Grid container item xs={12}>
          <span className={classes.circle}>2</span>

          <span
            style={{
              fontSize: "0.9rem",
              marginLeft: "0.5rem",
            }}
            className={classes.loanLabels}
          >
            Review loan parameters
          </span>
        </Grid>
      </Grid>
    );
  };

  const interestRateAndLtv = () => {
    return (
      <Grid container>
        <Grid item md={7} container className={classes.loanGrid}>
          <Grid item xs={5}>
            <span className={classes.loanLabels}>Interest Rate</span>
          </Grid>
          <Grid item container xs={7} className={classes.loanGridItem}>
            <BaseNumericInputFormat
              className={
                !!formErrors.noteRate
                  ? classes.loanPercentTextfieldsError
                  : classes.loanPercentTextfields
              }
              value={calculationData.noteRate}
              onInputChange={(values) =>
                handleNumericValues(values, "noteRate")
              }
              decimalScale={3}
              allowDecimal
              numericType="percent"
              required
            />
          </Grid>
        </Grid>
        <Grid item md={5} container className={classes.loanGrid}>
          <Grid item xs={5}>
            <span className={classes.loanLabels}>LTV</span>
          </Grid>
          <Grid item container xs={7} className={classes.loanGridItem}>
            <BaseNumericInputFormat
              className={
                calculationData.purchasePrice <= 0 ||
                  ((calculationData.loanType == "FHA" ||
                    calculationData.loanType == "VA") &&
                    ((calculationData.loanAmount - calculationData.oneTimePMI) /
                      calculationData.purchasePrice) *
                    100 >
                    96.5) ||
                  (calculationData.loanType == "Conventional" &&
                    (calculationData.loanAmount / calculationData.purchasePrice) *
                    100 >
                    97)
                  ? classes.loanPercentTextfieldsError
                  : classes.loanPercentTextfields
              }
              style={{ backgroundColor: "#ebebeb" }}
              readOnly={true}
              value={ltvValue}
              allowDecimal
              decimalScale={1}
              numericType="percent"
              required
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const loanAmount = () => {
    return (
      <Grid container>
        <Grid item md={7} container className={classes.loanGrid}>
          <Grid item xs={5}>
            <span className={classes.loanLabels}>Loan Amount</span>
          </Grid>
          <Grid item container xs={7} className={classes.loanGridItem}>
            <BaseNumericInputFormat
              className={
                !!formErrors.loanAmount
                  ? classes.loanBigTextfieldsError
                  : classes.loanBigTextfields
              }
              value={calculationData.loanAmount}
              onInputChange={(values) =>
                handleNumericValues(values, "loanAmount")
              }
              numericType="currency"
              allowDecimal
              required
            />
          </Grid>
        </Grid>
        <Grid item md={5} container className={classes.loanGrid}>
          <Grid
            item
            xs={5}
            style={{
              display: calculationData.loanType == "FHA" ? "flex" : "none",
            }}
          >
            <span className={classes.loanLabels}>Base Loan</span>
          </Grid>
          <Grid
            item
            container
            xs={7}
            className={classes.loanGridItem}
            style={{
              display: calculationData.loanType == "FHA" ? "flex" : "none",
            }}
          >
            <BaseNumericInputFormat
              className={classes.loanBigTextfields}
              value={calculationData.loanAmount - calculationData.oneTimePMI}
              style={{ backgroundColor: "#ebebeb" }}
              allowDecimal
              numericType="currency"
              readOnly
              required
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const loanOriginatorFee = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={3}>
          <span className={classes.loanLabels}>Loan Origination Fee</span>
        </Grid>
        <Grid item container xs={6} md={5} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.originationFeePerc}
            onInputChange={(values) =>
              handleNumericValues(values, "originationFeePerc")
            }
            allowDecimal
            decimalScale={3}
            numericType="percent"
          />
          <span className={classes.loanLabels}>or +</span>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.originationFeeAmt}
            onInputChange={(values) =>
              handleNumericValues(values, "originationFeeAmt")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.originationFeeAmt === 0
                ? calculationData.loanAmount *
                (calculationData.originationFeePerc / 100)
                : calculationData.loanAmount *
                (calculationData.originationFeePerc / 100) +
                calculationData.originationFeeAmt
            }
            allowDecimal
            readOnly
            numericType="currency"
          />
        </Grid>
      </Grid>
    );
  };

  const brokerFee = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={3}>
          <span className={classes.loanLabels}>Broker Fee</span>
        </Grid>
        <Grid item container xs={6} md={5} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.brokerFeePerc}
            onInputChange={(values) =>
              handleNumericValues(values, "brokerFeePerc")
            }
            allowDecimal
            decimalScale={3}
            numericType="percent"
          />
          <span className={classes.loanLabels}>or +</span>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.brokerFeeAmt}
            onInputChange={(values) =>
              handleNumericValues(values, "brokerFeeAmt")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.brokerFeeAmt === 0
                ? (calculationData.loanAmount * calculationData.brokerFeePerc) /
                100
                : (calculationData.loanAmount * calculationData.brokerFeePerc +
                  calculationData.brokerFeeAmt) /
                100
            }
            numericType="currency"
            readOnly
            allowDecimal
          />
        </Grid>
      </Grid>
    );
  };

  const originationCredit = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={3}>
          <span className={classes.loanLabels}>Origination Credit</span>
        </Grid>
        <Grid item container xs={6} md={5} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.lenderCreditPerc}
            onInputChange={(values) =>
              handleNumericValues(values, "lenderCreditPerc")
            }
            allowDecimal
            decimalScale={3}
            numericType="percent"
          />
          <span className={classes.loanLabels}>or +</span>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.lenderCreditAmt}
            onInputChange={(values) =>
              handleNumericValues(values, "lenderCreditAmt")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.lenderCreditAmt === 0
                ? (calculationData.lenderCreditPerc / 100) *
                calculationData.loanAmount
                : (calculationData.lenderCreditPerc / 100) *
                calculationData.loanAmount +
                calculationData.lenderCreditAmt
            }
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const loanDiscountPoints = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={3}>
          <span className={classes.loanLabels}>Loan Discount Points</span>
        </Grid>
        <Grid item container xs={6} md={5} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.discountPointsPerc}
            onInputChange={(values) =>
              handleNumericValues(values, "discountPointsPerc")
            }
            decimalScale={3}
            allowDecimal
            numericType="percent"
          />
          <span className={classes.loanLabels}>or +</span>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.discountPointsAmt}
            onInputChange={(values) =>
              handleNumericValues(values, "discountPointsAmt")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.discountPointsAmt === 0
                ? (calculationData.discountPointsPerc / 100) *
                calculationData.loanAmount
                : (calculationData.discountPointsPerc / 100) *
                calculationData.loanAmount +
                calculationData.discountPointsAmt
            }
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const interestToFirstPayment = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>Interest to First Payment</span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={
              !!formErrors.prepaidInterestDays
                ? classes.loanPercentTextfieldsError
                : classes.loanPercentTextfields
            }
            value={calculationData.prepaidInterestDays}
            onInputChange={(values) =>
              handleNumericValues(values, "prepaidInterestDays")
            }
            suffix={" days"}
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              (((calculationData.loanAmount * calculationData.noteRate) / 365) *
                calculationData.prepaidInterestDays) /
              100
            }
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const conventionalSinglePremiumPMI = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          display:
            calculationData.loanType === "Conventional" &&
              calculationData.oneTimePMI === 1
              ? "flex"
              : "none",
        }}
      >
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>
            Conventional Single Premium PMI
          </span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.oneTimePMI}
            readOnly
          />
        </Grid>
        <Grid
          item
          container
          xs={6}
          md={4}
          className={classes.loanGridItem}
        ></Grid>
      </Grid>
    );
  };

  const fhaUpMipMortgageInsurancePremium = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          display: calculationData.loanType === "FHA" ? "flex" : "none",
        }}
      >
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>
            FHA UFMIP Mortgage Insurance Premium
          </span>
        </Grid>
        <Grid item container xs={12} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={
              !!formErrors.oneTimePMI && calculationData.loanType === "FHA"
                ? classes.loanPercentTextfieldsError
                : classes.loanPercentTextfields
            }
            value={calculationData.oneTimePMI}
            onInputChange={(values) =>
              handleNumericValues(values, "oneTimePMI")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container md={4} className={classes.loanGridItem}></Grid>
      </Grid>
    );
  };

  const vaFundingFee = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          display: calculationData.loanType === "VA" ? "flex" : "none",
        }}
      >
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>VA Funding Fee</span>
        </Grid>
        <Grid item container xs={12} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.oneTimePMI}
            onInputChange={(values) =>
              handleNumericValues(values, "oneTimePMI")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container md={4} className={classes.loanGridItem}></Grid>
      </Grid>
    );
  };

  const mortgageInsuranceReserves = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          //display: calculationData.loanType === "FHA" ? "flex" : "none",
          display: "none",
        }}
      >
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>
            Mortgage Insurance Reserves
          </span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.mtgInsReserveMths}
            onInputChange={(values) =>
              handleNumericValues(values, "mtgInsReserveMths")
            }
            suffix={" mths"}
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.mtgInsReserveMths * calculationData.mgtInsMonthly
            }
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const hazardInsurancePremiumMonthly = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>
            Hazard Insurance Premium - monthly
          </span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.homeownerInsMo}
            onInputChange={(values) =>
              handleNumericValues(values, "homeownerInsMo")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={calculationData.homeownerInsMo * 12}
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const hazardInsuranceReserves = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>Hazard Insurance Reserves</span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.hoiReserveMths}
            onInputChange={(values) =>
              handleNumericValues(values, "hoiReserveMths")
            }
            suffix={" mths"}
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.hoiReserveMths * calculationData.homeownerInsMo
            }
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const countyPropTaxMonthly = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>County Prop Tax - monthly</span>
        </Grid>
        <Grid item container xs={12} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.propTaxMo}
            onInputChange={(values) => handleNumericValues(values, "propTaxMo")}
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container md={4} className={classes.loanGridItem}></Grid>
      </Grid>
    );
  };

  const countyPropTaxReserves = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>County Prop Tax Reserves</span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.propTaxReserveMths}
            onInputChange={(values) =>
              handleNumericValues(values, "propTaxReserveMths")
            }
            suffix={" mths"}
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.propTaxReserveMths * calculationData.propTaxMo
            }
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const floodInsurancePremium = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>Flood Insurance Premium</span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.floodInsMo}
            onInputChange={(values) =>
              handleNumericValues(values, "floodInsMo")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={calculationData.floodInsMo * 12}
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const floodInsuranceReserves = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>Flood Insurance Reserve</span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.floodInsReserveMths}
            onInputChange={(values) =>
              handleNumericValues(values, "floodInsReserveMths")
            }
            suffix={" mths"}
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.floodInsReserveMths * calculationData.floodInsMo
            }
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const otherInsurancePremium = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>Other Insurance Premium</span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.otherInsMo}
            onInputChange={(values) =>
              handleNumericValues(values, "otherInsMo")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={calculationData.otherInsMo * 12}
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const otherInsuranceReserves = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={12} md={5}>
          <span className={classes.loanLabels}>Other Insurance Reserve</span>
        </Grid>
        <Grid item container xs={6} md={3} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.otherInsReserveMths}
            onInputChange={(values) =>
              handleNumericValues(values, "otherInsReserveMths")
            }
            suffix={" mths"}
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.otherInsReserveMths * calculationData.otherInsMo
            }
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const lenderTitleInsurance = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{ marginBottom: "2rem" }}
      >
        <Grid item container xs={6} md={8}>
          <span className={classes.loanLabels}>Lender Title Insurrance</span>
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={
              !!formErrors.lenderTitleIns
                ? classes.loanBigTextfieldsError
                : classes.loanBigTextfields
            }
            value={calculationData.lenderTitleIns}
            onInputChange={(values) =>
              handleNumericValues(values, "lenderTitleIns")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
      </Grid>
    );
  };

  const cityCountyStampsMortgage = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item container xs={6} md={8}>
          <span className={classes.loanLabels}>
            City/ County/ Stamps on Mortgage
          </span>
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={calculationData.mtgstamps}
            onInputChange={(values) => handleNumericValues(values, "mtgstamps")}
            allowDecimal
            numericType="currency"
          />
        </Grid>
      </Grid>
    );
  };

  const stateTaxStampsOnDeed = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item container xs={6} md={8}>
          <span className={classes.loanLabels}>State Tax/ Stamps on Deed</span>
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={calculationData.deedstamps}
            onInputChange={(values) =>
              handleNumericValues(values, "deedstamps")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
      </Grid>
    );
  };

  const totalAmtPaid = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item container xs={6} md={8}>
          <span>TOTAL AMT PAID TO OTHERS ON YOUR BEHALF</span>
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={calculationData.totalAMTPaidToOthers}
            onInputChange={(values) =>
              handleNumericValues(values, "totalAMTPaidToOthers")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
      </Grid>
    );
  };

  const totalPrepaid = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item container xs={6} md={8}>
          <span>TOTAL PREPAID FINANCE CHARGE</span>
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={calculationData.prepaidFinanceCharges}
            onInputChange={(values) =>
              handleNumericValues(values, "prepaidFinanceCharges")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
      </Grid>
    );
  };

  const loanTerm = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={6} md={8}>
          <span className={classes.loanLabels}>Loan Term (not less than 120 months)</span>
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={
              !!formErrors.term
                ? classes.loanPercentTextfieldsError
                : classes.loanPercentTextfields
            }
            value={calculationData.term}
            suffix={" mths"}
            onInputChange={(values) => handleNumericValues(values, "term")}
            required
          />
        </Grid>
      </Grid>
    );
  };

  const monthlyPmiPremium = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          display:
            calculationData.loanType === "Conventional" ? "flex" : "none",
        }}
      >
        <Grid item container xs={6} md={8}>
          <span className={classes.loanLabels}>Monthly PMI Premium</span>
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={calculationData.mgtInsMonthly}
            onInputChange={(values) =>
              handleNumericValues(values, "mgtInsMonthly")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
      </Grid>
    );
  };

  const totalProposedPayment = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item xs={6} md={8}>
          <span className={classes.loanLabels}>
            Total Proposed Monthly Payment
          </span>
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={
              !!formErrors.totalHousingExpenses
                ? classes.loanBigTextfieldsError
                : classes.loanBigTextfields
            }
            value={calculationData.totalHousingExpenses}
            onInputChange={(values) =>
              handleNumericValues(values, "totalHousingExpenses")
            }
            allowDecimal
            numericType="currency"
            required
          />
        </Grid>
      </Grid>
    );
  };

  const salesContractPrice = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{ marginTop: "2rem" }}
      >
        <Grid item xs={6} md={8}>
          <span className={classes.loanLabels}>SALES CONTRACT PRICE</span>
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={
              !!formErrors.purchasePrice
                ? classes.loanBigTextfieldsError
                : classes.loanBigTextfields
            }
            value={calculationData.purchasePrice}
            onInputChange={(values) =>
              handleNumericValues(values, "purchasePrice")
            }
            allowDecimal
            required
            numericType="currency"
          />
        </Grid>
      </Grid>
    );
  };

  const otherCredits = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid
          style={{
            margin: "0.5rem",
          }}
        >
          <Grid container item xs={12}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
              }}
            >
              <span className={classes.circle}>3</span>
            </div>
            <span
              style={{
                fontSize: "0.9rem",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
                alignContent: "center",
              }}
            >
              Add third party credits to buyer
            </span>
          </Grid>
        </Grid>
        <div style={{ marginBottom: "1rem" }}>
          <span className={classes.loanLabels}>
            This section should include realtor credits or DPA funds for
            programs WITHOUT a monthly payment. Seller credit should NOT be
            included.
          </span>
        </div>
        <Grid item xs={12} md={3}>
          <span className={classes.loanLabels}>M. Other Credits*</span>
        </Grid>
        <Grid item container xs={6} md={5} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.otherCreditsPerc}
            onInputChange={(values) =>
              handleNumericValues(values, "otherCreditsPerc")
            }
            decimalScale={3}
            allowDecimal
            numericType="percent"
          />
          <span className={classes.loanLabels}>or +</span>
          <BaseNumericInputFormat
            className={classes.loanPercentTextfields}
            value={calculationData.otherCreditsAmt}
            onInputChange={(values) =>
              handleNumericValues(values, "otherCreditsAmt")
            }
            allowDecimal
            numericType="currency"
          />
        </Grid>
        <Grid item container xs={6} md={4} className={classes.loanGridItem}>
          <BaseNumericInputFormat
            className={classes.loanBigTextfields}
            value={
              calculationData.otherCreditsAmt === 0
                ? (calculationData.loanAmount *
                  calculationData.otherCreditsPerc) /
                100
                : (calculationData.loanAmount *
                  calculationData.otherCreditsPerc) /
                100 +
                calculationData.otherCreditsAmt
            }
            allowDecimal
            numericType="currency"
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <BaseCard>
      <BaseCardHeader title={"Loan Qualification Parameters"} />
      <BaseCardContent>
        <Grid container item xs={12} gap={1}>
          {loanType()}
          <Grid
            container
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            gap={1}
          >
            <Grid item lg={6} xs={12}>
              <Typography
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "0.5rem 0",
                }}
              >
                Complete the following fields from the Itemization of Amount
                Financed Document
              </Typography>
              {interestRateAndLtv()}
              {loanAmount()}
              <Typography
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "0.5rem 0",
                }}
              >
                OUR ORIGINATION SALES INCLUDE
              </Typography>
              <hr style={{ marginBottom: "1.5rem" }} />
              {loanOriginatorFee()}
              {brokerFee()}
              <Typography
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "0.5rem 0",
                }}
              >
                YOUR CREDIT OR CHARGE (POINTS)
              </Typography>
              <hr style={{ marginBottom: "1.5rem" }} />
              {originationCredit()}
              {loanDiscountPoints()}
              <Typography
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "0.5rem 0",
                }}
              >
                PREPAIDS
              </Typography>
              <hr style={{ marginBottom: "1.5rem" }} />
              {interestToFirstPayment()}
              {conventionalSinglePremiumPMI()}
              {fhaUpMipMortgageInsurancePremium()}
              {vaFundingFee()}
              {mortgageInsuranceReserves()}
              {hazardInsurancePremiumMonthly()}
              {hazardInsuranceReserves()}
              {countyPropTaxMonthly()}
              {countyPropTaxReserves()}
              {floodInsurancePremium()}
              {floodInsuranceReserves()}
              {otherInsurancePremium()}
              {otherInsuranceReserves()}
            </Grid>

            <Grid item lg={5} xs={12}>
              <Typography
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "0.5rem 0",
                }}
              >
                SETTLEMENT OR CLOSING COSTS
              </Typography>
              <hr style={{ marginBottom: "1.5rem" }} />
              {lenderTitleInsurance()}
              <Typography
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "0.5rem 0",
                }}
              >
                TRANSFER TAXES INCLUDES
              </Typography>
              <hr style={{ marginBottom: "1.5rem" }} />
              {cityCountyStampsMortgage()}
              {stateTaxStampsOnDeed()}
              <hr style={{ marginTop: "1.5rem" }} />
              {totalAmtPaid()}
              <hr />
              {totalPrepaid()}
              <hr style={{ marginBottom: "1.5rem" }} />
              {loanTerm()}
              {monthlyPmiPremium()}
              {totalProposedPayment()}
              {salesContractPrice()}
              {otherCredits()}
            </Grid>
          </Grid>
        </Grid>
      </BaseCardContent>
    </BaseCard>
  );
}
