import { AppRoutes } from "./PathConstants";

/**
 * Calls an API using the GET method
 * @param {string} apiPath The path to the API
 * @param {any} setDataFunc The function ref that will be called with the returned data as a parameter
 * @param {any} setLoadingFunc The function ref that will be called ones the execution finishes with a parameter value of true
 * @param {any} onError The function ref that will be called if there is an error, response status is passed as parameter or 0 if exception.
 * @param {string} token Bearer token string
 */

export function getApiRootPath() {
  if (window.location.origin.startsWith("https://qa"))
    return "https://qa-api.padzilly.app";
  if (
    window.location.origin.startsWith("https://www.") ||
    window.location.origin.startsWith("https://padzilly.com") ||
    window.location.origin.startsWith("https://padzilly.app")
  )
    return "https://api.padzilly.app";

  //return "https://localhost:7045";
  return "https://qa-api.padzilly.app";
}

export async function fetchApiData(
  apiPath,
  setDataFunc,
  setLoadingFunc,
  onError,
  token
) {
  const apiURL = `${getApiRootPath()}/${apiPath}`;

  try {
    //const token = await authService.getAccessToken();
    const response = await fetch(apiURL, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    //check if successfull
    if (response.ok) {
      const data = await response.json();
      if (setDataFunc) setDataFunc(data);
      if (setLoadingFunc) setLoadingFunc(false);
    } else {
      if (response.status === 401 || response.status === 403) {
        window.location.href = AppRoutes.Login;
      } else {
        if (setLoadingFunc) setLoadingFunc(false);

        if (onError) {
          try {
            const text = await response.text();
            //if valid json send

            onError(response.status, text);
          } catch (err) {
            onError("Error getting data", response.status);
          }
        }
      }
    }
  } catch (ex) {
    if (setLoadingFunc) setLoadingFunc(false);
    if (onError) onError("Error getting data", 0);
  }
}

/**
 * Calls an API passing the method POST, PUT or DELETE for GET call fetchApiData instead
 * @param {string} apiPath The path to the API
 * @param {string} method Method value POST, PUT or DELETE
 * @param {any} data The json object that will be send to the server, if none then pass null
 * @param {any} onSuccess The function ref that will be called ones the execution finishes if response contains json type it will pass the data as parameter
 * @param {any} onError The function ref that will be called if there is an error, error message is passed as parameter.
 * @param {string} token The bearer token.
 *
 */
export async function postApiData(
  apiPath,
  method,
  data,
  onSuccess,
  onError,
  token
) {
  try {
    const apiURL = `${getApiRootPath()}/${apiPath}`;
    //const token = await authService.getAccessToken();

    const requestOptions = {
      method: method,
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
    };

    if (data !== null) requestOptions.body = JSON.stringify(data);

    const response = await fetch(apiURL, requestOptions);

    if (response.ok) {
      if (onSuccess) {
        try {
          const text = await response.text();
          const resdata = JSON.parse(text);
          //if valid json send
          onSuccess(resdata);
        } catch (err) {
          onSuccess();
        }
      }
    } else {
      if (response.status === 401 || response.status === 403) {
        window.location.href = AppRoutes.Login;
      } else {
        if (onError) {
          try {
            const text = await response.text();
            //if valid json send
            console.log(text);
            console.log(response.status);

            onError(response.status, text);
          } catch (err) {
            onError("Error! Please try again later.", response.status);
          }
        }
      }
    }
  } catch (ex) {
    console.warn(ex);

    if (onError) onError("Error! Please try again later.", 0);
  }
}

/**
 * Calls an API passing the method POST, PUT or DELETE for GET call fetchApiData instead
 * @param {string} apiPath The path to the API
 * @param {string} method Method value POST, PUT or DELETE
 * @param {any} data The json object that will be send to the server, if none then pass null
 * @param {any} setCompleteFunc The function ref that will be called ones the execution finishes if response contains json type it will pass the data as parameter
 * @param {any} setErrorFunc The function ref that will be called if there is an error, error message is passed as parameter.
 * @param {string} token
 */
export async function postApiPdf(apiPath, data, onSuccess, onError, token) {
  try {
    const apiURL = `${getApiRootPath()}/${apiPath}`;

    let postMethod = "POST";

    const requestOptions = {
      method: postMethod,
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
    };

    if (data !== null) requestOptions.body = JSON.stringify(data);

    const response = await fetch(apiURL, requestOptions);

    //check if successfull
    if (response.ok) {
      try {
        const pdf = await response.blob();

        const file = new Blob([pdf], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        if (onSuccess) onSuccess("PDF downloaded");
      } catch (err) {
        if (onError) onError("Error downloading PDF");
      }
    } else {
      if (onError) {
        try {
          const text = await response.text();
          //if valid json send
          onError(text, response.status);
        } catch (err) {
          onError("Error getting offer letter", response.status);
        }
      }
    }
  } catch (ex) {
    if (onError) onError("Error getting data." + ex, 0);
  }
}
