import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import EmailIcon from "@mui/icons-material/Email";
/**
 * @typedef {Object} BaseInputProps
 * @property {string} [error]
 * @property {boolean} [hasError]
 */
/**
 * @param {BaseInputProps & Omit<import("@mui/material").TextFieldProps, 'error'>} props
 */
export default function BaseInput({ error, hasError, ...props }) {
  const [showPassword, setShowPassword] = useState(
    props?.type === "password" ? false : true
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <TextField
        {...props}
        sx={{ width: "100%", ...props.sx }}
        variant="outlined"
        size="small"
        autoComplete="off"
        error={!!error || hasError}
        helperText={error || " "}
        type={showPassword ? "text" : "password"}
        InputProps={{
          sx: {
            backgroundColor: "white",
          },
          endAdornment:
            props.type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : null,
          startAdornment: props.startIcon ? (
            <InputAdornment position="start">
              {props.startIcon === "person" ? (
                <PersonIcon />
              ) : props.startIcon === "phone" ? (
                <StayCurrentPortraitIcon />
              ) : props.startIcon === "email" ? (
                <EmailIcon />
              ) : null}
            </InputAdornment>
          ) : null,
        }}
      />
    </React.Fragment>
  );
}
