import React, { useContext } from "react";
import { Fragment } from "react";
import { Redirect, Route } from "react-router";
import { AppRoutes } from "../core/PathConstants";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";

export default function AuthRoute({ children, ...rest }) {
  const { user } = useContext(CurrentUserContext);

  //just temporal
  const billingDate = new Date(user.subscription?.billingDate);

  const billingDateWithExtraTime = new Date(billingDate);
  billingDateWithExtraTime.setHours(billingDate.getHours() + 72);

  return (
    <Fragment>
      {user?.success && user?.subscription && billingDateWithExtraTime > new Date() ? (
        <Route {...rest}>{children}</Route>
      ) : (
        <Route {...rest}>
          <Redirect to={AppRoutes.Login} />
        </Route>
      )}
    </Fragment>
  );
}
