import React, { useEffect } from "react";
import { useLoanData } from "../../../store/use-loan-data";
import { makeStyles } from "@mui/styles";
import { Grid, TextField, Typography } from "@mui/material";
import _ from "lodash";
import {
  LOAN_EDITOR_MODES,
  LOAN_VALIDATIONS,
} from "../../core/constants/constants";
import { formValidation } from "../../core/validationFunctions";
import BaseCard from "../../core/base-components/BaseCard/BaseCard";
import BaseCardHeader from "../../core/base-components/BaseCard/BaseCardHeader";
import BaseCardContent from "../../core/base-components/BaseCard/BaseCardContent";
import BaseInput from "../../core/base-components/BaseInput";
import BaseNumericInputFormat from "../../core/base-components/BaseNumericInputFormat";
import BaseMaskedInput from "../../core/base-components/BaseMaskedInput";
import { PHONE_MASK } from "../../core/constants/constants";

const useStyles = makeStyles((theme) => ({
  textFields: {
    width: "100%",
  },
  textFieldsInput: {
    fontSize: "1rem",
    textAlign: "center",
    backgroundColor: "white",
  },
  textFieldLabel: {
    fontSize: "16px",
  },
  realtorPaper: {
    backgroundColor: "#d4e0f3",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderWidth: 1,
    borderRadius: ".5rem",
    padding: "0.25rem",
    height: "fit-content",
  },
}));

export default function BuyerInfoForm({ mode }) {
  const buyerData = useLoanData.use.buyerInfo();
  const setBuyerData = useLoanData.use.setBuyerInfo();
  const formErrors = useLoanData.use.buyerInfoErrors();
  const setFormErrors = useLoanData.use.setBuyerInfoErrors();

  const classes = useStyles();

  const borrowerMthIncomeProps = {
    error: !!formErrors.borrowerMthIncome,
    helperText: formErrors.borrowerMthIncome ?? " ",
    label: "Bor Total Monthly Income",
    size: "small",
    variant: "outlined",
    name: "borrowerMthIncome",
    placeholder: "Bor Total Monthly Income",
    className: classes.textFields,
    InputProps: {
      classes: { input: classes.textFieldsInput },
    },
    InputLabelProps: {
      classes: {
        root: classes.textFieldLabel,
      },
    },
  };

  const coBorrowerMthIncomeProps = {
    error: !!formErrors.coBorrowerMthIncome,
    helperText: formErrors.coBorrowerMthIncome ?? " ",
    label: "CoBor Total Monthly Income",
    size: "small",
    variant: "outlined",
    name: "coBorrowerMthIncome",
    placeholder: "CoBor Total Monthly Income",
    className: classes.textFields,
    InputProps: {
      classes: { input: classes.textFieldsInput },
    },
    InputLabelProps: {
      classes: {
        root: classes.textFieldLabel,
      },
    },
  };

  const addtlCoBorrowerIncomeProps = {
    error: !!formErrors.addtlCoBorrowerIncome,
    helperText: formErrors.addtlCoBorrowerIncome ?? " ",
    label: "Additional CoBorrower Income",
    size: "small",
    variant: "outlined",
    name: "addtlCoBorrowerIncome",
    placeholder: "Additional CoBorrower Income",
    className: classes.textFields,
    InputProps: {
      classes: { input: classes.textFieldsInput },
    },
    InputLabelProps: {
      classes: {
        root: classes.textFieldLabel,
      },
    },
  };

  const totalCashInBankProps = {
    label: "Total Available Cash",
    size: "small",
    variant: "outlined",
    name: "totalCashInBank",
    placeholder: "Total Available Cash",
    className: classes.textFields,
    InputProps: {
      classes: { input: classes.textFieldsInput },
    },
    InputLabelProps: {
      classes: {
        root: classes.textFieldLabel,
      },
    },
    error: !!formErrors.totalCashInBank,
    helperText: formErrors.totalCashInBank ?? " ",
  };

  const handleTextInputValue = (e) => {
    let { name, value } = e.target;

    setBuyerData({
      ...buyerData,
      [name]: value,
    });

    if (name in LOAN_VALIDATIONS) {
      const error = formValidation(name, value, LOAN_VALIDATIONS) || "";

      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    }
  };

  const handleNumericValues = (value, name) => {
    setBuyerData({
      ...buyerData,
      [name]: value,
    });

    if (name in LOAN_VALIDATIONS) {
      const error = formValidation(name, value, LOAN_VALIDATIONS) || "";

      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    }
  };

  return (
    <BaseCard>
      <BaseCardHeader title={"Borrower Information"} />
      <BaseCardContent>
        <Grid container item xs={12} gap={1}>
          <Grid
            container
            item
            direction={"row"}
            justifyContent={"space-between"}
            gap={1}
          >
            <Grid item lg={3} md={5} xs={12}>
              <BaseInput
                label="Borrower First Name"
                name="borrowerFirstName"
                value={buyerData.borrowerFirstName}
                placeholder="Borrower First Name"
                onChange={handleTextInputValue}
                error={formErrors.borrowerFirstName}
                disabled={mode != LOAN_EDITOR_MODES.CREATE_LOAN}
                required
              />
            </Grid>
            <Grid item lg={3} md={5} xs={12}>
              <BaseInput
                label="Borrower Last Name"
                name="borrowerLastName"
                value={buyerData.borrowerLastName}
                placeholder="Borrower Last Name"
                onChange={handleTextInputValue}
                error={formErrors.borrowerLastName}
                disabled={mode != LOAN_EDITOR_MODES.CREATE_LOAN}
                required
              />
            </Grid>
            <Grid item lg={3} md={5} xs={12}>
              <BaseInput
                label="Borrower Email"
                name="borrowerEmail"
                value={buyerData.borrowerEmail}
                placeholder="Borrower Email"
                onChange={handleTextInputValue}
                error={formErrors.borrowerEmail}
                disabled={mode != LOAN_EDITOR_MODES.CREATE_LOAN}
                required
              />
            </Grid>
            <Grid item lg={3} md={5} xs={12}>
              <BaseMaskedInput
                label="Borrower Mobile Phone"
                mask={PHONE_MASK}
                name="borrowerMobilePhone"
                maskValue={buyerData.borrowerMobilePhone || ""}
                placeholder="Borrower Mobile Phone"
                onInputChange={handleTextInputValue}
                error={formErrors.borrowerMobilePhone}
                disabled={mode != LOAN_EDITOR_MODES.CREATE_LOAN}
              />
            </Grid>
            <Grid item lg={3} md={5} xs={12}>
              <BaseNumericInputFormat
                value={buyerData.borrowerMthIncome}
                onInputChange={(values) =>
                  handleNumericValues(values, "borrowerMthIncome")
                }
                allowDecimal
                numericType="currency"
                customInput={TextField}
                {...borrowerMthIncomeProps}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction={"row"}
            justifyContent={"space-between"}
            gap={1}
          >
            <Grid item lg={3} md={5} xs={12}>
              <BaseInput
                label="Street Address"
                name="streetAddress"
                value={buyerData.streetAddress}
                placeholder="Street Address"
                onChange={handleTextInputValue}
              />
            </Grid>
            <Grid item lg={3} md={5} xs={12}>
              <BaseInput
                label="Unit #"
                name="unitNo"
                value={buyerData.unitNo}
                placeholder="Unit #"
                onChange={handleTextInputValue}
              />
            </Grid>
            <Grid item lg={3} md={5} xs={12}>
              <BaseInput
                label="City"
                name="city"
                value={buyerData.city}
                placeholder="City"
                onChange={handleTextInputValue}
                error={formErrors.city}
              />
            </Grid>
            <Grid item lg={3} md={5} xs={12}>
              <BaseInput
                label="State"
                name="state"
                value={buyerData.state}
                placeholder="State"
                onChange={handleTextInputValue}
                error={formErrors.state}
              />
            </Grid>
            <Grid item lg={3} md={5} xs={12}>
              <BaseInput
                label="ZIP"
                name="zipCode"
                value={buyerData.zipCode}
                placeholder="ZIP"
                onChange={handleTextInputValue}
                error={formErrors.zipCode}
              />
            </Grid>
          </Grid>
          <Grid container item direction={"column"}>
            CoBorrower Information
            <Grid
              container
              item
              direction={"row"}
              justifyContent={"space-between"}
              gap={1}
            >
              <Grid item lg={3} md={5} xs={12}>
                <BaseInput
                  label="CoBorrower First Name"
                  name="coBorrowerFirstName"
                  value={buyerData.coBorrowerFirstName}
                  placeholder="CoBorrower First Name"
                  onChange={handleTextInputValue}
                  error={formErrors.coBorrowerFirstName}
                />
              </Grid>
              <Grid item lg={3} md={5} xs={12}>
                <BaseInput
                  label="CoBorrower Last Name"
                  name="coBorrowerLastName"
                  value={buyerData.coBorrowerLastName}
                  placeholder="CoBorrower Last Name"
                  onChange={handleTextInputValue}
                  error={formErrors.coBorrowerLastName}
                />
              </Grid>
              <Grid item lg={3} md={5} xs={12}>
                <BaseNumericInputFormat
                  value={buyerData.coBorrowerMthIncome}
                  onInputChange={(values) =>
                    handleNumericValues(values, "coBorrowerMthIncome")
                  }
                  allowDecimal
                  numericType="currency"
                  customInput={TextField}
                  {...coBorrowerMthIncomeProps}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction={"row"}
              justifyContent={"space-between"}
              gap={1}
            >
              <Grid
                container
                item
                direction={"column"}
                lg={3}
                md={5}
                xs={12}
                gap={1}
              >
                <Typography variant="subtitle1">
                  Additional CoBorrowers
                </Typography>
                <BaseNumericInputFormat
                  value={buyerData.addtlCoBorrowerIncome}
                  onInputChange={(values) =>
                    handleNumericValues(values, "addtlCoBorrowerIncome")
                  }
                  allowDecimal
                  numericType="currency"
                  customInput={TextField}
                  {...addtlCoBorrowerIncomeProps}
                />
              </Grid>
              <Grid
                container
                item
                direction={"column"}
                lg={3}
                md={5}
                xs={12}
                gap={1}
              >
                <Typography variant="subtitle1">
                  Avail Cash All Borrowers
                </Typography>
                <BaseNumericInputFormat
                  value={buyerData.totalCashInBank}
                  onInputChange={(values) =>
                    handleNumericValues(values, "totalCashInBank")
                  }
                  allowDecimal
                  numericType="currency"
                  customInput={TextField}
                  {...totalCashInBankProps}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={"row"} style={{ margin: "1rem 0" }}>
            <Grid
              container
              item
              className={classes.realtorPaper}
              style={{ padding: "0.45rem" }}
              gap={1}
            >
              <Typography variant="subtitle1">Realtor Information</Typography>
              <Grid
                container
                item
                direction={"row"}
                justifyContent={"space-between"}
                gap={1}
              >
                <Grid item lg={3} md={5} xs={12}>
                  <BaseInput
                    label="Realtor First Name"
                    name="realtorFirstName"
                    value={buyerData.realtorFirstName}
                    placeholder="Realtor First Name"
                    onChange={handleTextInputValue}
                    error={formErrors.realtorFirstName}
                  />
                </Grid>
                <Grid item lg={3} md={5} xs={12}>
                  <BaseInput
                    label="Realtor Last Name"
                    name="realtorLastName"
                    value={buyerData.realtorLastName}
                    placeholder="Realtor Last Name"
                    onChange={handleTextInputValue}
                    error={formErrors.realtorLastName}
                  />
                </Grid>
                <Grid item lg={3} md={5} xs={12}>
                  <BaseInput
                    label="Realtor Email"
                    name="realtorEmail"
                    value={buyerData.realtorEmail}
                    placeholder="Realtor Email"
                    onChange={handleTextInputValue}
                    error={formErrors.realtorEmail}
                  />
                </Grid>
                <Grid item lg={3} md={5} xs={12}>
                  <BaseMaskedInput
                    label="Realtor Mobile Phone"
                    mask={PHONE_MASK}
                    name="realtorMobilePhone"
                    maskValue={buyerData.realtorMobilePhone || ""}
                    placeholder="Realtor Mobile Phone"
                    onInputChange={handleTextInputValue}
                    error={formErrors.realtorMobilePhone}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BaseCardContent>
    </BaseCard>
  );
}
