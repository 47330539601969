//@ts-check

import { useContext, useEffect } from "react";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";
import { useRefresh } from "../../api/user/use-user";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../core/PathConstants";

export default function Refresh({ setIsLoading }) {
  const { onChangeUser } = useContext(CurrentUserContext);

  const refresh = useRefresh();

  const history = useHistory();

  useEffect(() => {
    const userEmail = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (userEmail && token) {
      const data = {
        email: userEmail,
        refreshToken: token,
      };
      refresh.mutate(data, {
        onSuccess: (result) => {
          //just temporal
          const billingDate = new Date(result.subscription?.billingDate);
          const billingDateWithExtraTime = new Date(billingDate);
          billingDateWithExtraTime.setHours(billingDate.getHours() + 72);
          console.log(billingDateWithExtraTime, new Date())

          if (result?.success && result?.subscription && billingDateWithExtraTime < new Date()) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            onChangeUser({});
            setIsLoading(false);
            history.push(AppRoutes.Login);
            return;
          }

          onChangeUser(result);
          setIsLoading(false);
        },
        onError: (e) => {
          console.log(e)
          history.push(AppRoutes.Login);
          setIsLoading(false);
        },
      });
    } else {
      setIsLoading(false);
      history.push(AppRoutes.Login);
    }
  }, []);

  return null;
}
