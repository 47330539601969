import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MaskedInput from "react-text-mask";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";

/**
 * @typedef {Object} BaseMaskedInputProps
 * @property {string} [maskValue]
 * @property {(string | RegExp)[]} [mask]
 * @property {string} [error]
 * @property {React.ChangeEventHandler<HTMLInputElement> | undefined} [onInputChange]
 * @property {boolean} [hasError]
 */
/**
 * @param {BaseMaskedInputProps & Omit<import("@mui/material").TextFieldProps, 'error'>} textFieldProps
 */
export default function BaseMaskedInput({
  maskValue,
  mask,
  error,
  hasError,
  onInputChange,
  ...textFieldProps
}) {
  return (
    <React.Fragment>
      <MaskedInput
        {...textFieldProps}
        mask={mask}
        guide={false}
        onChange={onInputChange}
        value={maskValue}
        render={(ref, props) => (
          <TextField
            {...props}
            inputRef={ref}
            sx={{ width: "100%", ...props.sx }}
            variant="outlined"
            size="small"
            autoComplete="off"
            error={!!error || hasError}
            helperText={error || " "}
            type={"text"}
            InputProps={{
              sx: {
                backgroundColor: "white",
              },
              startAdornment: props.startIcon ? (
                <InputAdornment position="start">
                  {props.startIcon === "phone" ? (
                    <StayCurrentPortraitIcon />
                  ) : null}
                </InputAdornment>
              ) : null,
            }}
          />
        )}
      />
    </React.Fragment>
  );
}
