import React, { useState, useContext } from "react";
import Tab from "@mui/material/Tab";
import LoanCalculationForm from "./LoanCalculationForm";
import BuyerInfoForm from "./BuyerInfoForm";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import AlertMessage from "../../core/AlertMessage";
import { Grid } from "@mui/material";
import BaseStickyActions from "../../core/base-components/BaseContent/BaseStickyActions";
import BaseButton from "../../core/base-components/BaseButton";
import { useLoanData } from "../../../store/use-loan-data";
import { useCreateLoan, useUpdateLoan } from "../../../api/loans/use-save-loan";
import { LOAN_EDITOR_MODES } from "../../core/constants/constants";
import { fetchBuyerByEmail } from "../../../api/buyer/use-buyer";
import { CurrentUserContext } from "../../../hooks/CurrentUserContext";
import { validateInitialLoanValues } from "../../../lib/utils";

export default function LoanEditorForm({ mode, onSubmitSuccess }) {
  const { user } = useContext(CurrentUserContext);

  const [alertState, setAlertState] = useState({
    show: false,
    message: "",
  });
  const [value, setValue] = React.useState("1");
  const [isValidLoanType, setIsValidLoanType] = useState(true);

  const { mutate: createLoan, isLoading: isCreatingLoan } = useCreateLoan();
  const { mutate: editLoan, isLoading: isEditingLoan } = useUpdateLoan();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isValidBuyerData = () => {
    const { buyerInfo, buyerInfoErrors, setBuyerInfoErrors } =
      useLoanData.getState();
    const errorsList = validateInitialLoanValues(buyerInfo);
    const allEmpty = Object.entries(errorsList).every(
      ([key, value]) => value === ""
    );
    setBuyerInfoErrors({ ...buyerInfoErrors, ...errorsList });
    return allEmpty;
  };

  const isValidBuyerEmail = async (email) => {
    try {
      const buyerResult = await fetchBuyerByEmail(user, email);
      if (buyerResult && buyerResult.LoanOriginatorId && buyerResult.LoanId)
        throw {
          message: `There is already an existing buyer with email ${buyerResult.BuyerEmail} and has already a loan officer and a loan qualification linked to his profile`,
        };
      if (
        buyerResult &&
        buyerResult.LoanOriginatorId &&
        buyerResult.LoanOriginatorId != user.Id &&
        !buyerResult.LoanId
      )
        throw {
          message: `This buyer already has a loan officer linked to his profile.  Please ask the buyer to remove the current Loan officer from his/her profile so you can add their Loan.`,
        };
      return true;
    } catch (error) {
      setAlertState({
        show: true,
        message:
          error.message ?? `Something went wrong checking email ${email}`,
      });
      return false;
    }
  };

  const isInvalidLTV = (calculationData) => {
    return (
      ((calculationData.loanType == "FHA" ||
        calculationData.loanType == "VA") &&
        ((calculationData.loanAmount - calculationData.oneTimePMI) /
          calculationData.purchasePrice) *
          100 >
          96.5) ||
      (calculationData.loanType == "Conventional" &&
        (calculationData.loanAmount / calculationData.purchasePrice) * 100 > 97)
    );
  };

  const isValidType = (loanType) => {
    const isValidLoanType = loanType != "";
    if (!isValidLoanType) {
      setIsValidLoanType(false);
    }
    return isValidLoanType;
  };

  const isValidCalculationData = () => {
    const { calculationData, calculationDataErrors, setCalculationDataErrors } =
      useLoanData.getState();
    const errorsList = validateInitialLoanValues(calculationData);
    if (errorsList.oneTimePMI !== "" && calculationData.loanType !== "FHA") {
      errorsList.oneTimePMI = "";
    }
    const allEmpty = Object.entries(errorsList).every(
      ([key, value]) => value === ""
    );
    setCalculationDataErrors({ ...calculationDataErrors, ...errorsList });
    const isValidLoanType = isValidType(calculationData.loanType);
    const isInvalidLTVValue = isInvalidLTV(calculationData);
    return allEmpty && !isInvalidLTVValue && isValidLoanType;
  };

  const onCreateLoan = (loanData) => {
    createLoan(loanData, {
      onError: (error) => {
        if (error.response.status === 400) {
          setAlertState({
            show: true,
            message: error.response.data,
          });
        } else {
          setAlertState({
            show: true,
            message: "Error creating loan. Please try again later",
          });
        }
      },
      onSuccess: () => {
        onSubmitSuccess();
      },
    });
  };

  const onEditLoan = (loanData) => {
    editLoan(loanData, {
      onError: () => {
        setAlertState({
          show: true,
          message: "Error updating loan data. Please try again later",
        });
      },
      onSuccess: () => {
        onSubmitSuccess();
      },
    });
  };

  const getMipOrVaFee = (loanValues) => {
    if (loanValues.loanType === "FHA" || loanValues.loanType === "VA") {
      return (
        loanValues.oneTimePMI / (loanValues.loanAmount - loanValues.oneTimePMI)
      );
    } else return 0;
  };

  const getValidNumericValue = (value) => {
    return isNaN(value) ||
      value === null ||
      value === Infinity ||
      value === -Infinity
      ? 0.0
      : value;
  };

  const getLoanLTV = (loanValues) => {
    return loanValues.loanType == "FHA" || loanValues.loanType == "VA"
      ? ((loanValues.loanAmount - loanValues.oneTimePMI) /
          loanValues.purchasePrice) *
          100
      : (loanValues.loanAmount / loanValues.purchasePrice) * 100;
  };

  const getOneTimePMI = (loanData) => {
    return loanData.loanType === "Conventional" && loanData.oneTimePMI > 1
      ? 0
      : loanData.oneTimePMI;
  };

  const getInsMtgInsPeriodFactor1 = (loanData) => {
    return getValidNumericValue(loanData.mgtInsMonthly / loanData.loanAmount);
  };

  const handleSubmit = async () => {
    const { buyerInfo, calculationData } = useLoanData.getState();

    const isValidEmail =
      mode === LOAN_EDITOR_MODES.CREATE_LOAN
        ? await isValidBuyerEmail(buyerInfo.borrowerEmail)
        : true;

    if (isValidEmail) {
      let loanData = {
        ...buyerInfo,
        ...calculationData,
      };
      loanData.ltv = getLoanLTV(loanData);
      loanData.downPaymentPerc = 100 - loanData.ltv;
      loanData.insMtgInsPeriodFactor1 = getInsMtgInsPeriodFactor1(loanData);
      loanData.mipOrVAFee = getMipOrVaFee(loanData);
      loanData.oneTimePMI = getOneTimePMI(loanData);
      if (mode != LOAN_EDITOR_MODES.EDIT_LOAN) {
        onCreateLoan(loanData);
      } else {
        onEditLoan(loanData);
      }
    }
  };

  const floatingButtons = () => {
    return (
      <BaseStickyActions
        style={{
          background: "white",
          borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Grid container item xs={6} justifyContent="flex-end">
          <BaseButton
            onClick={() => {
              if (!isValidBuyerData()) {
                setValue("1");
                setAlertState({
                  show: true,
                  message:
                    "Please fix the buyer info errors and complete all the required data before saving the loan",
                });
              } else if (!isValidCalculationData()) {
                setValue("2");
                setAlertState({
                  show: true,
                  message:
                    "Please fix the calculation errors and complete all the required data before saving the loan",
                });
              } else handleSubmit();
            }}
            label={"Save"}
            loading={isCreatingLoan || isEditingLoan}
          />
        </Grid>
      </BaseStickyActions>
    );
  };

  return (
    <Grid item xs={12}>
      <AlertMessage alert={alertState} />
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label="Buyer Information" value="1" />
          <Tab label="Loan Qualification Parameters" value="2" />
        </TabList>
        <TabPanel value="1" sx={{ paddingLeft: 0, paddingRight: 0 }}>
          <BuyerInfoForm mode={mode} />
        </TabPanel>
        <TabPanel value="2" sx={{ paddingLeft: 0, paddingRight: 0 }}>
          <LoanCalculationForm
            isValidLoanType={isValidLoanType}
            setIsValidLoanType={setIsValidLoanType}
          />
        </TabPanel>
      </TabContext>
      {floatingButtons()}
    </Grid>
  );
}
