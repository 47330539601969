const isText = RegExp(/^[A-Z ]+$/i);
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
const isPhone = RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/); // us
const isZip = RegExp(/^[0-9]{5}([- /]?[0-9]{4})?$/); // us
const isNumber = RegExp(/^\d+$/);
const isWebSIte = RegExp(
  /(:?(?:https?:\/\/)?(?:www\.)?)?[-a-z0-9]+\.(?:com|gov|org|net|edu|biz)/g
);
const isPassword = RegExp(
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,20}$/
);

export function formValidation(name, value, schema) {
  const { validate, minLength, maxLength } = schema[name];
  let error = "";
  if (minLength && value.length < minLength)
    error = `Minimum of ${minLength} characters are required.`;
  else if (maxLength && value.length > maxLength)
    error = `Maximum length of ${maxLength} exceeded!`;
  if (!validate) return error;

  if (
    (validate != "biggerThanZero" && value != "") ||
    validate == "biggerThanZero"
  ) {
    switch (validate) {
      case "text":
        if (!isText.test(value)) {
          error = "This field accepts text only.";
        }
        break;

      case "number":
        if (!isNumber.test(value)) {
          error = "This field accepts numbers only.";
        }
        break;

      case "email":
        if (!isEmail.test(value)) {
          error = "Please enter a valid email address.";
        }
        break;

      case "phone":
        if (!isPhone.test(value)) {
          error = "Please enter a valid phone number. i.e: xxx-xxx-xxxx";
        }
        break;

      case "zip":
        if (!isZip.test(value)) {
          error = "Please enter a valid zip code.";
        }
        break;

      case "checkbox":
        if (!value) error = "Please select a value.";
        break;

      case "website":
        if (!isWebSIte.test(value)) {
          error = "Please enter a valid website URL";
        }
        break;

      case "biggerThanZero":
        if (value <= 0) {
          error = "This value should be bigger than zero";
        }
        break;

      case "biggerThan120":
        if (value < 120) {
          error = "This value should be bigger than 120";
        }
        break;

      case "password":
        if (!isPassword.test(value)) {
          error =
            "Please enter a valid password with at least one uppercase, one lowercase, one digit and one special character. No white spaces are allowed.";
        }
        break;

      default:
        break;
    }
  }

  return error;
}

export const defaultThemePrimaryColor = "#1D285B";
export const defaultThemeSecondaryColor = "#F1592A";
export const noLogoSrc = "/lenders/images/noLogo.jpg";
export const propertySrc = "/lenders/images/property-placeholder.jpg";

//size in MB
export const imageValidation = (imageFile, size) => {
  var extension = imageFile.type.split("/")[1];
  var validExtensions = ["gif", "png", "jpg", "jpeg", "svg+xml"];

  return imageFile.size < size * 1048576 && validExtensions.includes(extension);
};
