//@ts-check
import React from "react";
import { Card } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    borderWidth: 1,
    borderRadius: ".5rem",
  },
}));

/**
 * @param {import("@mui/material").CardProps} props
 */
export default function BaseCard({ ...props }) {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined" {...props}>
      {props.children}
    </Card>
  );
}
