import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";

/**
 * @typedef {Object} BaseCheckBoxProps
 * @property {Partial<import("@mui/material").FormControlLabelProps>} formControlLabelProps
 */
/**
 * @param {BaseCheckBoxProps  & import("@mui/material").CheckboxProps} props
 */
export const BaseCheckBox = ({ formControlLabelProps, ...checkboxProps }) => {
  return (
    <FormControlLabel
      {...formControlLabelProps}
      sx={formControlLabelProps.sx || { margin: "0 3px 0 0px" }}
      control={
        <Checkbox
          {...checkboxProps}
          color={checkboxProps?.color || "primary"}
        />
      }
    />
  );
};
