//@ts-check
import React from "react";
import { Grid, Typography } from "@mui/material";

/**
 * @typedef {Object} BaseTitleProps
 * @property {string} title
 * @property {React.ReactNode} [children]
 */

/**
 * @param {BaseTitleProps} props
 */

export default function BaseTitle({ title, children }) {
  return (
    <Grid container item xs={12} justifyContent={"space-between"}>
      <Typography variant="h5">{title || ""}</Typography>
      {children}
    </Grid>
  );
}
