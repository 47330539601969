//@ts-check
import { client } from "../common";
import * as React from "react";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";
import { ApiPaths } from "../../components/core/PathConstants";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const useUpdateLoan = () => {
  const { user } = React.useContext(CurrentUserContext);

  const useUpdateLoan = async (data) => {
    return client
      .put(ApiPaths.Loans, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => response.data);
  };
  return useMutation(useUpdateLoan);
};

export const useCreateLoan = () => {
  const { user } = React.useContext(CurrentUserContext);

  const useCreateLoan = async (data) => {
    return client
      .post(ApiPaths.Loans, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => response.data);
  };
  return useMutation(useCreateLoan);
};

export const useParseLoanPDF = () => {
  const { user } = React.useContext(CurrentUserContext);

  const useParseLoanPDF = async (data) => {
    return client
      .post(ApiPaths.PDFParser, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => response.data);
  };
  return useMutation(useParseLoanPDF);
};
