import { createContext } from "react";

/**
 * @typedef {Object} UserContextType
 * @property {import("../types/User").User} user
 * @property {(newUser: User) => void} onChangeUser
 */

/** @type {React.Context<UserContextType>} */
export const CurrentUserContext = createContext({});
